import request from "superagent";
import { getSessionToken } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

const getConsents = async () => {
  try {
    var response = await request
      .post(config.adaptiveUrl + "WebServices/generic/Privacy.asmx/Consents")
      .send({})
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken());

    if (!response.ok) {
      // eslint-disable-next-line
      console.error("Received an error from server");
      return [];
    }
    response = response.body.d;

    if (response.exception || !response.success) {
      // eslint-disable-next-line
      console.error(response.exception || "Received an error from server");
    }
    return response.records || [];
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    return [];
  }
};

const accept = async () => {
  try {
    var response = await request
      .post(config.adaptiveUrl + "WebServices/generic/Privacy.asmx/Accept")
      .send({
        version: config.privacyVersion,
        category: "telltur_user_email_consent"
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken());

    if (!response.ok) {
      // eslint-disable-next-line
      console.error("Received an error from server");
      return false;
    }
    response = response.body.d;

    if (response.exception || !response.success) {
      // eslint-disable-next-line
      console.error(response.exception || "Received an error from server");
    }
    return response.success || false;
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    return false;
  }
};

export { getConsents, accept };
