import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import config from "../config/TellTur";
import friluftsrradBG from "../static/friluftsraad-bg.svg";
import "./FriluftsradListPage.scss";

const mapStateToProps = state => ({
  friluftsraad: state.readFriluftsraad.records
});

const FriluftsradListPage = ({ friluftsraad, history }) => {
  const handleClick = useCallback(
    url => {
      history.push(`/friluftsrad/${url}`);
    },
    [history]
  );

  return (
    <div className="tell-tur--friluftsrad-list-page">
      <div className="tell-tur--friluftsrad-list-title">
        <h2>Friluftsråd og konkurranser</h2>
        <p>
          Nedenfor ser du en oversikt over friluftsrådene som deltar i TellTur. Klikk deg inn til
          ditt lokale friluftsråd for å lese mer om konkurranser og annen aktivitet der du bor!
        </p>
      </div>
      <div className="tell-tur--friluftsrad-list-container">
        {friluftsraad.map(fr => (
          <div
            key={fr.uri}
            onClick={() => handleClick(fr.uri)}
            className="tell-tur--friluftsrad-container"
          >
            <img
              src={
                config.adaptiveUrl +
                "WebServices/generic/Media.asmx/Download?thumbnail_size=medium&uuid=" +
                fr.thumbnail
              }
              alt={fr.navn}
            />
            <footer>
              <img src={friluftsrradBG} alt="" />
              <h4>{fr.navn}</h4>
            </footer>
          </div>
        ))}
      </div>
    </div>
  );
};

FriluftsradListPage.propTypes = {
  friluftsraad: PropTypes.array.isRequired
};

export default connect(mapStateToProps, null)(withRouter(FriluftsradListPage));
