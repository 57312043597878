// CONSTANTS
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// ACTIONS
export function locationChange (location = '/') {
  return {
    type: 'LOCATION_CHANGE',
    payload: location
  };
}

// SPECIALIZED ACTION CREATER
export const updateLocation = ({ dispatch }) => {
  return nextLocation => dispatch(locationChange(nextLocation));
};

// REDUCER
const initialState = null;
export default function locationReducer (state = initialState, action) {
  return action.type === LOCATION_CHANGE ? action.payload : state;
}
