import React from "react";
import ProfilePage from "../components/ProfilePage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetails, updateUser, deleteUser } from "../redux/user";
import { getCompanies } from "../redux/companies";
import { readMembers, addMember } from "../redux/familymembers";
import CompetitionPage from "./competition/CompetitionPage";
import {
  readJoinedCompetitions,
  unjoinCompetition
} from "../redux/competition";
import scopedBindActionCreators from "../utils/scopedReducer";
import { actions as digiThemeActions } from "../utils/digiTheme";
import config from "../config/TellTur";

const mapDispatchToProps = dispatch => {
  return {
    readMembers: bindActionCreators(readMembers, dispatch),
    addMember: bindActionCreators(addMember, dispatch),
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
    deleteUser: bindActionCreators(deleteUser, dispatch),
    getCompanies: bindActionCreators(getCompanies, dispatch),
    readJoinedCompetitions: bindActionCreators(
      readJoinedCompetitions,
      dispatch
    ),
    unjoinCompetition: bindActionCreators(unjoinCompetition, dispatch),
    getAllFriluftsraad: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "readFriluftsraad"
    ),
    readFriluftsraadMedlemskommuner: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "friluftsraadMedlemskommuner"
    )
  };
};

const mapStateToProps = state => ({
  members: state.familyMembers.members,
  memberError: state.familyMembers.error,
  membersLoading: state.familyMembers.loading,
  userLoading: state.user.loading,
  userError: state.user.error,
  user: state.user.user,
  companies: state.companies.companies,
  companiesLoading: state.companies.loading,
  allFriluftsraad: state.readFriluftsraad.records,
  allFriluftsraadLoading: state.readFriluftsraad.loading,
  friluftsraadMedlemskommuner: state.friluftsraadMedlemskommuner.records,
  friluftsraadMedlemskommunerLoading: state.friluftsraadMedlemskommuner.loading
});

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { serverError: null, userError: null };

    this.addThenReadMembers = this.addThenReadMembers.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount() {
    this.setState({
      serverError: null,
      userError: null
    });
    this.props.getAllFriluftsraad(
      config.friluftsraadDt.columns,
      null,
      config.mapProjCode,
      0,
      1000,
      null
    );
    this.props.readFriluftsraadMedlemskommuner(
      config.friluftsraadMedlemskommunerDt.columns,
      null,
      config.mapProjCode,
      0,
      1000,
      null
    );
    this.props.readJoinedCompetitions();
    if (!this.props.user) {
      this.props.getUserDetails();
    } else {
      this.props.readMembers(this.props.user.uuid);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && !this.props.user) {
      this.props.readMembers(nextProps.user.uuid);
    }
    if (
      nextProps.memberError &&
      nextProps.memberError !== this.state.serverError
    ) {
      this.setState({ serverError: nextProps.memberError });
    }
    if (nextProps.userError && nextProps.userError !== this.props.userError) {
      this.setState({ userError: nextProps.userError });
    }
  }

  addThenReadMembers(member) {
    this.setState({
      serverError: null
    });
    this.props.addMember(member, () => {
      this.props.getUserDetails();
    });
  }

  updateUser(user) {
    this.setState({ userError: null });
    this.props.updateUser(user);
  }

  render() {
    const {
      userLoading,
      membersLoading,
      companiesLoading,
      addMember,
      updateUser,
      userError,
      memberError,
      joinedCompetitions,
      members,
      allFriluftsraadLoading,
      friluftsraadMedlemskommunerLoading,
      ...rest
    } = this.props;
    const isLoading =
      userLoading ||
      membersLoading ||
      companiesLoading ||
      allFriluftsraadLoading ||
      friluftsraadMedlemskommunerLoading;

    return (
      <div>
        <ProfilePage
          {...rest}
          isLoading={isLoading}
          serverError={this.state.serverError}
          userError={this.state.userError}
          addMember={this.addThenReadMembers}
          updateUser={this.updateUser}
          members={members}
        />
        <CompetitionPage />
      </div>
    );
  }
}

MyProfile.propTypes = {
  page: PropTypes.object,
  members: PropTypes.array,
  memberError: PropTypes.object,
  membersLoading: PropTypes.bool,
  userLoading: PropTypes.bool,
  user: PropTypes.object,
  readMembers: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  userError: PropTypes.object,
  getAllFriluftsraad: PropTypes.func,
  allFriluftsraadLoading: PropTypes.bool,
  companies: PropTypes.array,
  companiesLoading: PropTypes.bool,
  getCompanies: PropTypes.func.isRequired,
  readJoinedCompetitions: PropTypes.func.isRequired,
  joinedCompetitions: PropTypes.array,
  unjoinCompetition: PropTypes.func.isRequired,
  readFriluftsraadMedlemskommuner: PropTypes.func.isRequired,
  friluftsraadMedlemskommunerLoading: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
