export default {
  adaptiveUrl: "https://friluftsforvaltning.avinet.no/",
  konkurannserUuid: "1f21a335-cc5c-432d-a3bb-c2f74f70a643",
  konkurannseTurmalUuid: "7ef9fc8b-343a-4109-9563-5a42e16162c8",
  cms_site_id: 0,
  guiUrl: "https://www.telltur.no",
  sti: {
    guiId: 40,
    activeStiLayer: "layer_144",
    grayStiLayer: "layer_145"
  },
  articleModuleUuid: "c46f6991-8d03-4bbd-815e-443f38c445ba",
  friluftsraadDt: {
    uuid: "03845a0f-6d2e-4318-90d1-bd2a61812a5f",
    columns: ["id", "rolle_id", "uuid", "uri", "navn", "thumbnail"]
  },
  friluftsraadMedlemskommunerDt: {
    uuid: "c27e8c08-1c7c-4c3c-9775-644deb97637f",
    columns: ["id", "uuid", "fr_id", "kommune"]
  },
  dtMunicipality: {
    uuid: "2f0a64ec-6f85-4523-839b-2a439b86bc24",
    columns: ["gid", "komm", "navn"]
  },
  parkingsDt: {
    uuid: "324a9681-dede-48ee-abaf-af76481f95e3",
    columns: ["uuid", "id", "type_id", "beskrivelse", "bilde_multimedia", "navn", "tilrettlagt"]
  },
  companyDigiThemeUuid: "4b5ea2a3-434a-4802-b056-2f81d834b853",
  emptyFriluftsraadUuid: "559908b6-7603-4eeb-abc5-5f09220961d3",
  vesteralenFriluftsraadUuid: "ff0b7103-c420-4a3e-8a16-51f0c333da5e",
  mapProjCode: "3857",
  gm_lang_code: "nb",
  appName: "TellTur",
  privacyVersion: 1,
  consentEmail: "telltur_user_email_consent",
  instagram: {
    userId: "224871",
    clientId: "9f1162178b01c872612327e1f03b1188",
    accessToken: "224871.3a81a9f.96d4029a304b46c7b27c8f15344ffd43"
  },
  fylkesnummer: [
    {
      navn: "Agder",
      nummer: "42"
    },
    {
      navn: "Innlandet",
      nummer: "34"
    },
    {
      navn: "Møre og Romsdal",
      nummer: "15"
    },
    {
      navn: "Nordland",
      nummer: "18"
    },
    {
      navn: "Oslo",
      nummer: "03"
    },
    {
      navn: "Rogaland",
      nummer: "11"
    },
    {
      navn: "Troms og Finnmark",
      nummer: "54"
    },
    {
      navn: "Trøndelag",
      nummer: "50"
    },
    {
      navn: "Vestfold og Telemark",
      nummer: "38"
    },
    {
      navn: "Vestland",
      nummer: "46"
    },
    {
      navn: "Viken",
      nummer: "30"
    }
  ]
};
