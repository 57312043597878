import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import { translate } from "react-translate";
import { Link } from "react-router-dom";

const generateTripList = (trips, handleDeleteTrip, noTripsText, t) => {
  if (!trips || !trips.length) {
    return <div>{noTripsText}</div>;
  }
  const sortedByMunicipality = trips.reduce((aggregateTripsByMunicipality, trip) => {
    if (aggregateTripsByMunicipality[trip.turmaal_kommune] === undefined) {
      aggregateTripsByMunicipality[trip.turmaal_kommune] = [];
    }
    aggregateTripsByMunicipality[trip.turmaal_kommune].push(trip);
    return aggregateTripsByMunicipality;
  }, {});

  return Object.keys(sortedByMunicipality).map(k => {
    return (
      <div key={k} className="trip-list--municipality-rows-wrapper">
        <span className="trip-list--municipality-name">{k !== "null" ? k : ""}</span>
        <div className="trip-list-row">
          <div className="date-cell header-cell">{t("Date")}</div>
          <div className="destination-cell header-cell">{t("Trip")}</div>
          <div className="points-cell header-cell">{t("Points")}</div>
          <div className="delete-cell header-cell" />
        </div>
        {generateTripRows(sortedByMunicipality[k], handleDeleteTrip, t)}
      </div>
    );
  });
};

const generateTripRows = (trips, handleDeleteTrip, t) => {
  return trips.map(trip => {
    if (!trip.telltur_turmaal_id || !trip.id || !trip.turmaal_navn) {
      return (
        <div key={trip.id || "default-key"} className="trip-list-row">
          <div className="date-cell">{trip.date.toLocaleDateString(t("locale"))}</div>
          <div className="destination-cell">Turmål slettet</div>
          <div className="points-cell">{trip.points}</div>
          <div className="delete-cell">
            <button className="btn-link" onClick={() => handleDeleteTrip(trip.id)}>
              {t("DeleteTrip")}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div key={trip.id} className="trip-list-row">
          <div className="date-cell">{trip.date.toLocaleDateString(t("locale"))}</div>
          <div className="destination-cell">
            <Link to={`/turmaal/${trip.telltur_turmaal_id.replace("_", "/")}`}>
              {trip.turmaal_navn}
            </Link>
          </div>
          <div className="points-cell">{trip.points}</div>
          <div className="delete-cell">
            <button className="btn-link" onClick={() => handleDeleteTrip(trip.id)}>
              {t("DeleteTrip")}
            </button>
          </div>
        </div>
      );
    }
  });
};

const TripList = ({ trips, handleDeleteTrip, noTripsText, loading, t }) => {
  return (
    <div>
      <div className="trip-list">
        {loading ? (
          <div className="trip-list--loader">
            <LoadingIndicator />{" "}
          </div>
        ) : (
          <React.Fragment>
            {generateTripList(trips, handleDeleteTrip, noTripsText, t)}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

TripList.propTypes = {
  trips: PropTypes.array,
  handleDeleteTrip: PropTypes.func,
  noTripsText: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default translate("TripList")(TripList);
