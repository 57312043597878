// If serializing a C# ExpandoObject (dynamic object) you can run this method to return a proper javascript object.
// Only useful if you have a flat object, for instance serialized data-rows
// var records = [
//   [{ Key: 'id', Value: 1 }, { Key: 'name', Value: 'Ola' }],
//   [{ Key: 'id', Value: 2 }, { Key: 'name', Value: 'Kari' }]
// ];
const fixKeyValueArraysToJsObject = records => {
  return records.map(r =>
    r.reduce((obj, item) => {
      if (item !== undefined) {
        obj[item.Key] = item.Value;
      }
      return obj;
    }, {})
  );
};

export { fixKeyValueArraysToJsObject };
