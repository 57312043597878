import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import { TranslatorProvider } from "react-translate";
import translations from "../translations/translations";
import {
  translations as reactArticleModuleTranslations,
  ArticleModule
} from "@avinet/react-article-module";
import Router from "../routes";
import DefaultMap from "../components/Map/DefaultMap";
import config from "../config/TellTur";

class App extends React.Component {
  getTranslations(lang) {
    return Object.assign(translations[lang], reactArticleModuleTranslations[lang]);
  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <TranslatorProvider translations={this.getTranslations("no")}>
          <DefaultMap>
            <ArticleModule
              adaptiveUrl={config.adaptiveUrl}
              articleModuleUuid={config.articleModuleUuid}
            >
              <Router />
            </ArticleModule>
          </DefaultMap>
        </TranslatorProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
