import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { getPath } from "../utils/Path";
import "./Footer.scss";

const Footer = () => {
  // eslint-disable-next-line no-restricted-globals
  const currentPage = getPath(location);
  return (
    <React.Fragment>
      {currentPage.indexOf("friluftsrad") === -1 && (
        <div className="tell-tur-cms--main-content ">
          <div>
            {/* remove currentPage === 'telltur' before deployment */}
            {(currentPage === "index" || currentPage === "telltur/") && (
              <div className="tell-tur-cms--main-content__description">
                <h2>Slik kommer du igang</h2>
                <p className="strong">
                  <Icon name="checkmarkInCircle" />{" "}
                  <Link to="/alleturmaal" className="link">
                    Gå til kartet
                  </Link>{" "}
                  og zoom inn i kartet for forslag til turmål. Filtrer treffene på ønsket turtype.
                </p>
                <p className="strong">
                  <Icon name="checkmarkInCircle" /> Vet du navnet på turmålet kan du finne det fra
                  søkefeltet.
                </p>
                <p className="strong">
                  <Icon name="checkmarkInCircle" /> Klikk deg deretter inn på hver enkelt
                  turmålbeskrivelse for detaljer om turen.
                </p>

                <p>
                  Når du har gått turen kan du "telle" den ved å registrere turkoden som finnes ute
                  på turmålet – her på nettsiden eller via GPS-registrering i appen "Norgeskart
                  friluftsliv". Dermed kan du{" "}
                  <Link to="/friluftsrad" className="link">
                    delta i konkurranser arrangert av ditt friluftsråd!
                  </Link>
                </p>
              </div>
            )}

            <div>
              <img
                src="https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=1dad9597-9fac-435b-b1d3-27906da700c8"
                alt=""
              />
              <div className="tell-tur--app-badge-container">
                <a
                  className="register-trip-page--app-link"
                  href="https://play.google.com/store/apps/details?id=no.avinet.norgeskart&amp;hl=nb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="register-trip-page--android"
                    src="https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=84986b48-a521-4258-8b18-5a3b64bff801"
                    alt=""
                  />
                </a>
                <a
                  className="register-trip-page--app-link"
                  href="https://itunes.apple.com/no/app/norgeskart-friluftsliv/id1350671128?l=nb&amp;mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="register-trip-page--ios"
                    src="https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=3f9c1651-73ce-4317-93bb-508d23281e58"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="tell-tur-cms--main-subcontent">
        <div className="tell-tur-cms--main-subcontent-box">
          <h2 className="sub-header">Vil du ha flere turforslag?</h2>
          <p>
            Turportalen{" "}
            <a href="https://ut.no" target="_blank" rel="noopener noreferrer">
              ut.no
            </a>{" "}
            tilbyr ulike kart og flere turforslag, pluss tips om hytter og severdigheter rundt om i
            hele landet. Her kan alle søke frem turforslag som passer seg.
          </p>
          <p>
            Ut.no er Norges nasjonale turportal og er et samarbeidsprosjekt mellom DNT, Statskog og
            Friluftsrådenes Landsforbund.
          </p>
        </div>
        <div className="tell-tur-cms--main-subcontent-box">
          <h2 className="sub-header">Hva er et friluftsråd?</h2>
          <p>
            Et friluftsråd er et samarbeid mellom to eller flere kommuner og eventuelt
            organisasjoner om felles friluftsoppgaver. Les mer om de mange friluftsrådene og det
            landsomfattende samarbeidsorganet Friluftsrådenes Landsforbund på{" "}
            <a href="http://www.friluftsrad.no" target="_blank" rel="noopener noreferrer">
              www.friluftsrad.no
            </a>
            .
          </p>
        </div>
      </div>
      <div className="footer--container">
        <div className="footer--content">
          <p>
            © 2018{" "}
            <a href="https://www.friluftsrad.no/" target="_blank" rel="noopener noreferrer">
              Friluftsrådenes Landsforbund
            </a>{" "}
            | Webløsning ved{" "}
            <a
              href="https://www.asplanviak.no/kompetanse/digitale-tjenester"
              target="_blank"
              rel="noopener noreferrer"
            >
              Asplan Viak AS
            </a>{" "}
            |{" "}
            <a
              href="https://www.friluftsrad.no/om-fl/forvaltningslosning/samtykke"
              target="_blank"
              rel="noopener noreferrer"
            >
              Personvern, informasjonskapsler
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
