// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_MAP_HISTORY = 'UPDATE_MAP_HISTORY';

// ------------------------------------
// Actions
// ------------------------------------

const updateHistory = (lastZoomLevel, lastMapCenter) => ({
  type: UPDATE_MAP_HISTORY,
  payload: {
    lastZoomLevel,
    lastMapCenter
  }
});

// ------------------------------------
// Action creators
// ------------------------------------
export function updateMapHistory (lastZoomLevel, lastMapCenter) {
  return dispatch => {
    dispatch(updateHistory(lastZoomLevel, lastMapCenter));
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  lastZoomLevel: undefined,
  lastMapCenter: undefined
};

export function mapHistoryReducer (state = initialState, action) {
  switch (action.type) {
  case UPDATE_MAP_HISTORY:
    return Object.assign({}, state, {
      lastMapCenter: action.payload.lastMapCenter,
      lastZoomLevel: action.payload.lastZoomLevel
    });
  default:
    return state;
  }
}
