import locationReducer from "./location";
import destination from "./destination";
import { combineReducers } from "redux";
import { authReducer } from "@avinet/react-adaptive-auth";
import { pageReducer, navReducer } from "@avinet/react-adaptive-cms";
import digiThemeReducer from "../utils/digiTheme";
import { userReducer } from "./user";
import { tripsReducer } from "./trips";
import { statisticsReducer } from "./statistics";
import { friluftsraadReducer } from "./friluftsraad";
import { companyReducer } from "./companies";
import { mapHistoryReducer } from "./mapHistory";
import { familyMembersReducer } from "./familymembers";
import { joinCompetitionReducer } from "./competition";
import config from "../config/TellTur";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    location: locationReducer,
    page: pageReducer,
    nav: navReducer,
    familyMembers: familyMembersReducer,
    user: userReducer,
    trips: tripsReducer,
    destination: destination,
    friluftsraad: friluftsraadReducer,
    statistics: statisticsReducer,
    companies: companyReducer,
    mapHistory: mapHistoryReducer,
    auth: authReducer,
    joinCompetitionReducer: joinCompetitionReducer,
    municipalities: digiThemeReducer(
      "municipalities",
      config.adaptiveUrl,
      config.dtMunicipality.uuid
    ),
    competition: digiThemeReducer("competition", config.adaptiveUrl, config.konkurannserUuid),
    konkurannseTurmal: digiThemeReducer(
      "konkurannseTurmal",
      config.adaptiveUrl,
      config.konkurannseTurmalUuid
    ),
    readFriluftsraad: digiThemeReducer(
      "readFriluftsraad",
      config.adaptiveUrl,
      config.friluftsraadDt.uuid
    ),
    friluftsraadMedlemskommuner: digiThemeReducer(
      "friluftsraadMedlemskommuner",
      config.adaptiveUrl,
      config.friluftsraadMedlemskommunerDt.uuid
    ),
    parkings: digiThemeReducer("parkings", config.adaptiveUrl, config.parkingsDt.uuid),
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
