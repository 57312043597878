import React from "react";
import PropTypes from "prop-types";
import CompetitionResults from "../../containers/competition/CompetitionResults";
import ResultCard from "../../containers/Results/ResultCard";
import { translate } from "react-translate";
import { STATISTICS_TYPE } from "../../redux/statistics";
import { getPageSections } from "../../utils/Page";

import "./ResultsPage.scss";

class ResultsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFriluftsraadStatisticsType: STATISTICS_TYPE.POINTS,
      currentCompanyStatisticsType: STATISTICS_TYPE.POINTS,
      myFriluftsraad: true,
      myBedrift: true
    };
    this.handleSwitchToggle = this.handleSwitchToggle.bind(this);
  }

  handleSwitchToggle(key) {
    this.setState(prevState => ({ [key]: !prevState[key] }));
  }

  render() {
    const {
      companyStatistics,
      friluftsraadStatistics,
      t,
      selectedMember,
      page,
      isGuest
    } = this.props;

    const {
      currentFriluftsraadStatisticsType,
      currentCompanyStatisticsType,
      myFriluftsraad,
      myBedrift
    } = this.state;
    const sections = getPageSections(page);

    return (
      <div className="tell-tur-results">
        <div
          dangerouslySetInnerHTML={{
            __html: sections && sections["FriluftsraadSection"]
          }}
        />
        <div className="tell-tur-results--navigation-container">
          <div
            onClick={() =>
              this.setState({
                currentFriluftsraadStatisticsType: STATISTICS_TYPE.POINTS
              })
            }
            className={
              "tell-tur-results--navigation-tab " +
              (currentFriluftsraadStatisticsType === STATISTICS_TYPE.POINTS
                ? "active"
                : "")
            }
          >
            {t("MostPoints")}
          </div>
          <div
            onClick={() =>
              this.setState({
                currentFriluftsraadStatisticsType: STATISTICS_TYPE.UNIQUE
              })
            }
            className={
              "tell-tur-results--navigation-tab " +
              (currentFriluftsraadStatisticsType === STATISTICS_TYPE.UNIQUE
                ? "active"
                : "")
            }
          >
            {t("UniqueDestinations")}
          </div>
        </div>
        {friluftsraadStatistics &&
          currentFriluftsraadStatisticsType === STATISTICS_TYPE.POINTS && (
            <div className="result-page--result-list-container">
              <ResultCard
                results={friluftsraadStatistics.results}
                loading={friluftsraadStatistics.loading}
                member={selectedMember}
                fromYear={2018}
                isGuest={isGuest}
                myFriluftsraad={myFriluftsraad}
                switchLabel={{ On: t("AllFr"), Off: t("MyFr") }}
                switchValue={myFriluftsraad}
                switchKey={"myFriluftsraad"}
                handleSwitchToggle={this.handleSwitchToggle}
                statisticsType={currentFriluftsraadStatisticsType}
                displayProps={["nick_name", "aggregate"]}
                headersWithSearch={[t("Nickname"), "", t("Points")]}
              />
            </div>
          )}
        {friluftsraadStatistics &&
          currentFriluftsraadStatisticsType === STATISTICS_TYPE.UNIQUE && (
            <div className="result-page--result-list-container">
              <ResultCard
                results={friluftsraadStatistics.results}
                loading={friluftsraadStatistics.loading}
                member={selectedMember}
                fromYear={2018}
                isGuest={isGuest}
                myFriluftsraad={myFriluftsraad}
                switchLabel={{ On: t("AllFr"), Off: t("MyFr") }}
                switchValue={myFriluftsraad}
                switchKey={"myFriluftsraad"}
                handleSwitchToggle={this.handleSwitchToggle}
                statisticsType={currentFriluftsraadStatisticsType}
                displayProps={["nick_name", "aggregate"]}
                headersWithSearch={[t("Nickname"), "", t("Destination")]}
              />
            </div>
          )}
        {selectedMember && selectedMember.bedrift_id && companyStatistics && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: sections && sections["CompanySection"]
              }}
            />
            <div className="tell-tur-results--navigation-container">
              <div
                onClick={() =>
                  this.setState({
                    currentCompanyStatisticsType: STATISTICS_TYPE.POINTS
                  })
                }
                className={
                  "tell-tur-results--navigation-tab " +
                  (currentCompanyStatisticsType === STATISTICS_TYPE.POINTS
                    ? "active"
                    : "")
                }
              >
                {t("MostPoints")}
              </div>
              <div
                onClick={() =>
                  this.setState({
                    currentCompanyStatisticsType: STATISTICS_TYPE.UNIQUE
                  })
                }
                className={[
                  "tell-tur-results--navigation-tab ",
                  currentCompanyStatisticsType === STATISTICS_TYPE.UNIQUE
                    ? "active"
                    : "",
                  !myBedrift ? "disabled" : ""
                ].join(" ")}
              >
                {t("UniqueDestinations")}
              </div>
            </div>
            {currentCompanyStatisticsType === STATISTICS_TYPE.POINTS && (
              <div className="result-page--result-list-container">
                <ResultCard
                  results={companyStatistics.results}
                  loading={companyStatistics.loading}
                  member={selectedMember}
                  fromYear={2018}
                  isGuest={isGuest}
                  isCompany
                  ignorePlace
                  myBedrift={myBedrift}
                  switchLabel={{ On: t("AllCompanies"), Off: t("MyCompany") }}
                  switchValue={myBedrift}
                  switchKey={"myBedrift"}
                  handleSwitchToggle={this.handleSwitchToggle}
                  statisticsType={currentCompanyStatisticsType}
                  displayProps={
                    myBedrift
                      ? ["nick_name", "aggregate"]
                      : [
                          "bedrift_navn",
                          "antall_deltagere",
                          "total_poeng",
                          "snitt_poeng"
                        ]
                  }
                  headers={
                    myBedrift
                      ? ["#", t("Nickname"), t("Points")]
                      : [
                          "#",
                          t("CompanyName"),
                          t("Participants"),
                          t("TotalPoints"),
                          t("PointsNorm")
                        ]
                  }
                />
              </div>
            )}
            {currentCompanyStatisticsType === STATISTICS_TYPE.UNIQUE && (
              <div className="result-page--result-list-container">
                <ResultCard
                  results={companyStatistics.results}
                  loading={companyStatistics.loading}
                  member={selectedMember}
                  fromYear={2018}
                  isGuest={isGuest}
                  isCompany
                  myBedrift={myBedrift}
                  switchLabel={{ On: t("AllCompanies"), Off: t("MyCompany") }}
                  switchValue={myBedrift}
                  switchKey={"myBedrift"}
                  switchDisable={
                    currentCompanyStatisticsType === STATISTICS_TYPE.UNIQUE
                  }
                  handleSwitchToggle={this.handleSwitchToggle}
                  statisticsType={currentCompanyStatisticsType}
                  displayProps={
                    myBedrift
                      ? ["nick_name", "aggregate"]
                      : [
                          "bedrift_navn",
                          "antall_deltagere",
                          "snitt_poeng",
                          "total_poeng"
                        ]
                  }
                  headers={
                    myBedrift
                      ? ["#", t("Nickname"), t("Points")]
                      : [
                          "#",
                          t("CompanyName"),
                          t("Participants"),
                          t("PointsNorm"),
                          t("TotalPoints")
                        ]
                  }
                />
              </div>
            )}
          </div>
        )}
        {selectedMember && <CompetitionResults member={selectedMember} />}
      </div>
    );
  }
}

ResultsPage.propTypes = {
  friluftsraadStatistics: PropTypes.object.isRequired,
  companyStatistics: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  selectedMember: PropTypes.object,
  page: PropTypes.object.isRequired,
  isGuest: PropTypes.bool
};

export default translate("ResultsPage")(ResultsPage);
