import React from "react";
import { translate } from "react-translate";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Title from "./Title";
import { ResetPasswordForm } from "@avinet/react-adaptive-auth";
import "./ResetPasswordPage.scss";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordSuccessfullyReset: false
    };
    this.handleReset = this.handleReset.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  handleCancel(evt) {
    evt.preventDefault();
    this.goBack();
  }

  handleReset() {
    this.setState({ passwordSuccessfullyReset: true });
  }

  goBack() {
    this.props.history.push("/logginn");
  }

  render() {
    const { t, page, baseUrl } = this.props;
    return (
      <div className="tell-tur-page">
        <Title title={page.title} />
        <h1>{page.title}</h1>
        <div
          className="tell-tur-page--intro-text"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        {!this.state.passwordSuccessfullyReset && (
          <ResetPasswordForm
            baseUrl={baseUrl}
            emailText={t("Email")}
            submitText={t("SendNewPassword")}
            cancelText={t("Cancel")}
            errorText={t("CouldNotResetPassword")}
            showLabels
            onCancel={this.handleCancel}
            onPasswordReset={this.handleReset}
          />
        )}
        {this.state.passwordSuccessfullyReset && (
          <div className="reset-password-form--info-text">
            <p>{t("PasswordResetInfoText")} - </p>
            <Link to="/logginn">{t("GoBack")}</Link>
          </div>
        )}
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  baseUrl: PropTypes.string
};

export default withRouter(translate("ResetPasswordPage")(ResetPasswordPage));
