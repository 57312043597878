import React from "react";
import PropTypes from "prop-types";
import ResultList from "../../components/Results/ResultList";
import Switch from "../../components/Switch";
import YearSelector from "../../components/YearSelector";
import Pagination from "../../components/Pagination";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ReadAllCompaniesPointStatistics,
  ReadStatisticsWithSearch,
  readCompanyStatistics
} from "../../redux/statistics";

const mapDispatchToProps = dispatch => {
  return {
    ReadAllCompaniesPointStatistics: bindActionCreators(ReadAllCompaniesPointStatistics, dispatch),
    ReadStatisticsWithSearch: bindActionCreators(ReadStatisticsWithSearch, dispatch),
    readCompanyStatistics: bindActionCreators(readCompanyStatistics, dispatch)
  };
};

class ResultCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLimit: 25,
      currentPage: undefined,
      currentRecords: undefined,
      selectedYear: null,
      switchValue: true,
      searchValue: "",
      searchResults: undefined
    };
    this.onYearSelected = this.onYearSelected.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedYear, searchValue } = this.state;
    const { statisticsType, switchValue, isCompany } = this.props;

    if (
      prevState.selectedYear !== selectedYear ||
      prevProps.statisticsType !== statisticsType ||
      prevProps.switchValue !== switchValue
    ) {
      this.updateStatistics(isCompany, statisticsType, selectedYear, searchValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.switchValue !== nextProps.switchValue) {
      this.setState({ searchValue: "", searchResults: undefined });
    }
  }

  onPageChanged = data => {
    const { currentPage, totalPages, pageLimit } = data;
    const { results } = this.props;

    const offset = (currentPage - 1) * pageLimit;
    const currentRecords = results.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentRecords, totalPages });
  };

  onYearSelected(year) {
    const selectedYear = parseInt(year);
    this.setState({ selectedYear });
  }

  get getHeaders() {
    const { headersWithSearch } = this.props;
    headersWithSearch[0] = (
      <input
        className="result-card--search"
        type="text"
        placeholder="søk på brukernavn"
        value={this.state.searchValue}
        ref="searchValue"
        onChange={this.handleSearch}
      />
    );
    return headersWithSearch;
  }

  handleSearch() {
    const { results } = this.props;

    const searchResults =
      results &&
      results.filter(r =>
        r.nick_name.toLowerCase().includes(this.refs.searchValue.value.toLowerCase())
      );

    this.setState({
      searchValue: this.refs.searchValue.value,
      searchResults: searchResults
    });
  }

  updateStatistics(isCompany, statisticsType, year, search = null) {
    const { isGuest, member, myFriluftsraad, myBedrift } = this.props;

    let selectedFrilufstraadId = isGuest
      ? null
      : !isGuest && !myFriluftsraad
      ? null
      : member.selected_friluftsraad_uuid;

    if (!isGuest && isCompany && !myBedrift) {
      selectedFrilufstraadId = member.selected_friluftsraad_uuid;
    }

    const companyId = isGuest ? null : !isGuest && !myBedrift ? null : member.bedrift_id;

    if (isCompany) {
      if (companyId) {
        this.props.readCompanyStatistics(
          companyId,
          member.selected_friluftsraad_uuid,
          statisticsType,
          year
        );
      } else {
        this.props.ReadAllCompaniesPointStatistics(
          member.selected_friluftsraad_uuid,
          statisticsType,
          year
        );
      }
    } else if (isGuest) {
      this.props.ReadStatisticsWithSearch(selectedFrilufstraadId, statisticsType, year, search);
    } else {
      this.props.ReadStatisticsWithSearch(selectedFrilufstraadId, statisticsType, year, search);
    }
  }

  render() {
    const {
      t,
      results,
      member,
      fromYear,
      isGuest,
      switchLabel,
      switchKey,
      switchValue,
      switchDisable,
      handleSwitchToggle,
      displayProps,
      headers,
      headersWithSearch,
      loading
    } = this.props;
    const { pageLimit, currentRecords, searchResults } = this.state;
    return (
      <React.Fragment>
        <div className="tell-tur-results--navigation-options">
          {!isGuest && (
            <Switch
              labelOn={switchLabel.On}
              labelOff={switchLabel.Off}
              value={!switchValue}
              key={switchKey}
              onChange={() => handleSwitchToggle(switchKey)}
              disable={switchDisable}
            />
          )}
          <YearSelector
            fromYear={fromYear}
            onYearSelected={this.onYearSelected}
            infoText={t("ChooseYear")}
          />
        </div>
        <div className="result-page--result-list-container">
          <ResultList
            results={
              searchResults && searchResults.length > 0
                ? searchResults
                : results && results.length > pageLimit
                ? currentRecords
                : results
            }
            displayProps={displayProps}
            headers={headersWithSearch ? this.getHeaders : headers}
            loading={loading}
            member={member}
            currentPage={this.state.currentPage}
            pageLimit={pageLimit}
            ignorePlace={this.props.ignorePlace}
          >
            <div className="result-page--result-list-container__page-options">
              {results && results.length > pageLimit && (
                <Pagination
                  totalRecords={
                    searchResults && searchResults.length > 0
                      ? searchResults.length
                      : results.length
                  }
                  pageNeighbours={1}
                  pageLimit={pageLimit}
                  onPageChanged={this.onPageChanged}
                />
              )}
            </div>
          </ResultList>
        </div>
      </React.Fragment>
    );
  }
}

ResultCard.propTypes = {
  t: PropTypes.func.isRequired,
  switchLabel: PropTypes.object.isRequired,
  switchValue: PropTypes.bool,
  switchKey: PropTypes.string,
  switchDisable: PropTypes.bool,
  statisticsType: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  isCompany: PropTypes.bool,
  results: PropTypes.array,
  isGuest: PropTypes.bool,
  loading: PropTypes.bool,
  headers: PropTypes.array,
  headersWithSearch: PropTypes.array,
  displayProps: PropTypes.array,
  handleSwitchToggle: PropTypes.func.isRequired,
  fromYear: PropTypes.number.isRequired,
  member: PropTypes.object,
  myBedrift: PropTypes.bool,
  myFriluftsraad: PropTypes.bool,
  ReadAllCompaniesPointStatistics: PropTypes.func.isRequired,
  readCompanyStatistics: PropTypes.func.isRequired,
  ReadStatisticsWithSearch: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(translate("ResultCard")(ResultCard));
