import React from 'react';
// import PropTypes from 'prop-types';
import RegisterTripPage from '../components/RegisterTripPage';

class RegisterTripView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentPath: ''
    };
  }

  render () {
    return (
      <div className='tell-tur'>
        <RegisterTripPage />
      </div>
    );
  }
}

RegisterTripView.propTypes = {};

export default RegisterTripView;
