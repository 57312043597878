import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Icon from "../components/Icon";
import { Nav, Link, navActions } from "@avinet/react-adaptive-cms";
import { getSessionToken } from "@avinet/react-adaptive-auth";
import TellturSearch from "../containers/TellturSearch";
import { withRouter } from "react-router-dom";
import Logo from "../static/logo_telltur_hvit.png";
import { getPath } from "../utils/Path";
import "./NavBar.scss";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(Object.assign({}, navActions), dispatch);
};

const mapStateToProps = state => ({
  nav: state.nav.nav
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
      searchOpen: false,
      currentPath: ""
    };

    this.onResultSelected = this.onResultSelected.bind(this);
  }

  componentDidMount() {
    const { loadNav, location } = this.props;
    const path = getPath(location);
    this.setState({ currentPath: path });
    loadNav();
  }

  componentWillReceiveProps(nextProps) {
    const path = getPath(nextProps.location);
    if (path !== this.state.currentPath) {
      this.setState({ currentPath: path });
    }
  }

  getProfileNav() {
    const { t } = this.props;

    if (getSessionToken()) {
      return [
        {
          menu_uri: "minprofil",
          title: t("MyProfile"),
          uri: "minprofil"
        },
        {
          menu_uri: "registrertur",
          title: "Registrer en tur",
          uri: "registrertur"
        },
        {
          menu_uri: "mineturer",
          title: "Mine turer",
          uri: "mineturer"
        },
        {
          menu_uri: "resultater",
          title: "Resultater",
          uri: "resultater"
        },
        {
          menu_uri: "loggut",
          title: t("LogOut"),
          uri: "loggut"
        }
      ];
    } else {
      return [
        {
          menu_uri: "resultater",
          title: "Resultater",
          uri: "resultater"
        },
        {
          menu_uri: "logginn",
          title: "Logg inn / Registrer deg",
          uri: "logginn"
        }
      ];
    }
  }

  getOmTellTurNav() {
    const { nav } = this.props;
    const omTellTurItems = nav.filter(i => {
      if (["omtelltur", "hjelp"].some(nav => i.uri.includes(nav))) {
        return true;
      }
      return false;
    });
    return omTellTurItems;
  }

  getNavItems() {
    const { nav } = this.props;
    const navItems = nav.filter(i => {
      if (
        ["resultater", "registrertur", "friluftsrad", "omtelltur", "hjelp"].some(nav =>
          i.uri.includes(nav)
        )
      ) {
        return false;
      }
      return true;
    });
    return navItems;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.nav !== nextProps.nav) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  onResultSelected(result) {
    const { history } = this.props;
    history.push("/turmaal/" + result.id.replace("_", "/"));
    this.setState({ searchOpen: false });
  }

  render() {
    const { navOpen, searchOpen } = this.state;

    let navItems = this.getNavItems();
    let profileNav = this.getProfileNav();
    let omTellTurNav = this.getOmTellTurNav();

    return (
      <div className="nav-bar--container">
        <Link to="/">
          <img className="nav-bar--header-image" src={Logo} alt="" />
        </Link>
        <div className={["nav-bar--search", !searchOpen ? "nav-bar--search-hidden" : ""].join(" ")}>
          <TellturSearch onResultSelected={this.onResultSelected} isOpen={searchOpen} />
        </div>
        <button
          className={
            searchOpen ? "nav-bar--search-button nav-bar--search-active" : "nav-bar--search-button"
          }
          onClick={() =>
            this.setState({
              searchOpen: !searchOpen,
              navOpen: false
            })
          }
        >
          <Icon name="search" />
        </button>
        <div className={["nav-bar--menu", navOpen ? " nav-bar--menu-active" : ""].join(" ")}>
          <input
            type="checkbox"
            className="nav-bar--hamburger"
            value={navOpen}
            checked={navOpen}
            onChange={() =>
              this.setState({
                navOpen: !navOpen,
                searchOpen: false
              })
            }
          />
          <span />
          <span />
          <span />
          <Nav
            nav={navItems}
            onNavClick={() =>
              this.setState({
                navOpen: false,
                searchOpen: false
              })
            }
          >
            <React.Fragment>
              <div className="nav-bar--omTellTur-menu">
                {omTellTurNav.map(n => (
                  <Link
                    key={n.uri}
                    to={n.menu_uri}
                    onClick={() =>
                      this.setState({
                        navOpen: false,
                        searchOpen: false
                      })
                    }
                  >
                    {n.title}
                  </Link>
                ))}
              </div>
              <div className="nav-bar--profile-menu">
                {profileNav.map(n => (
                  <Link
                    className={"nav-bar--profile-menu-" + n.uri}
                    key={n.uri}
                    to={n.menu_uri}
                    onClick={() =>
                      this.setState({
                        navOpen: false,
                        searchOpen: false
                      })
                    }
                  >
                    {n.title}
                  </Link>
                ))}
              </div>
            </React.Fragment>
          </Nav>
        </div>
        {/* currentPath !== "index" && (
          <HideableBanner
            url="https://www.friluftsrad.no/om-fl/forvaltningslosning/korona"
            textContent="Pga. smitterisiko skal turpostkasser ikke brukes. Registrer din
              tur gjennom appen Norgeskart friluftsliv"
          />
        ) */}
      </div>
    );
  }
}

NavBar.propTypes = {
  t: PropTypes.func.isRequired,
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  history: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("NavBar")(withRouter(NavBar)));
