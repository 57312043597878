import React from "react";
import PropTypes from "prop-types";
import TextEditableControl from "./TextEditableControl";
import EditableSearchDropdown from "./EditableSearchDropdown";
import PopupBox from "./PopupBox";
import { translate } from "react-translate";

class MyProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { displayPopup: false, key: undefined, newValue: undefined };
    this.handlePopUp = this.handlePopUp.bind(this);
  }

  handlePopUp() {
    const { handleUserSubmit, t } = this.props;
    const { key, newValue } = this.state;

    return (
      <PopupBox onClose={() => handleUserSubmit(key, newValue)} closeBtnText={"Ok"}>
        <div className="telltur-profile-form--popup">
          <p>{t("ReminderText1")}</p>
        </div>
      </PopupBox>
    );
  }

  render() {
    const {
      t,
      isLoading,
      // eslint-disable-next-line
      nick_name,
      firstname,
      surname,
      mobile,
      email,
      municipality,
      municipalities,
      yearofbirth,
      validationErrors,
      handleFamilyMemberSubmit,
      handleUserSubmit
    } = this.props;

    return (
      <div>
        {this.state.displayPopup && this.handlePopUp()}
        <form className="tell-tur-form no-buttons">
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="text"
            labelText={t("Nickname")}
            /* eslint-disable */
            controlValue={nick_name}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.nick_name}
            onSubmit={newValue => handleFamilyMemberSubmit("nick_name", newValue)}
            /* eslint-enable */
          />
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="text"
            labelText={t("Firstname")}
            controlValue={firstname}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.firstname}
            onSubmit={newValue => handleUserSubmit("firstname", newValue)}
          />
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="text"
            labelText={t("Surname")}
            controlValue={surname}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.surname}
            onSubmit={newValue => handleUserSubmit("surname", newValue)}
          />
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="tel"
            labelText={t("Mobile")}
            controlValue={mobile}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.mobile}
            onSubmit={newValue => handleUserSubmit("mobile", newValue)}
          />
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="email"
            labelText={t("Email")}
            controlValue={email}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.email}
            onSubmit={newValue => handleUserSubmit("email", newValue)}
          />
          <EditableSearchDropdown
            placeholder={t("Municipality")}
            controlClassName="tell-tur-form--control"
            labelText={t("Municipality")}
            inputType="Text"
            onSubmit={(key, newValue) => {
              this.setState({
                displayPopup: true,
                key,
                newValue
              });
            }}
            controlValue={
              isNaN((municipality && parseInt(municipality)) || undefined)
                ? ""
                : municipality && parseInt(municipality)
            }
            errorClassName="error municipality-error"
            dropdownItems={municipalities}
            errorText={validationErrors.municipality}
            labelPropName="navn"
            valuePropName="komm"
            controlKey="municipality"
          />
          <TextEditableControl
            controlClassName="tell-tur-form--control"
            inputType="number"
            labelText={t("YearOfBirth")}
            controlValue={yearofbirth}
            submitText={t("Save")}
            disabled={isLoading}
            errorText={validationErrors.yearofbirth}
            onSubmit={newValue => handleFamilyMemberSubmit("yearofbirth", newValue)}
            min={new Date().getFullYear() - 120}
            max={new Date().getFullYear()}
          />
        </form>
      </div>
    );
  }
}

MyProfileForm.propTypes = {
  t: PropTypes.func,
  isLoading: PropTypes.bool,
  nick_name: PropTypes.string,
  firstname: PropTypes.string,
  surname: PropTypes.string,
  mobile: PropTypes.string,
  email: PropTypes.string,
  municipality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yearofbirth: PropTypes.number,
  validationErrors: PropTypes.object.isRequired,
  handleFamilyMemberSubmit: PropTypes.func.isRequired,
  handleUserSubmit: PropTypes.func.isRequired,
  municipalities: PropTypes.array
};

export default translate("MyProfileForm")(MyProfileForm);
