import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import Icon from "./Icon";
import PopupBox from "./PopupBox";
import { translate } from "react-translate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { provideMapState } from "@avinet/react-openlayers";
import enkel from "../static/gradering_enkel.jpg";
import middels from "../static/gradering_middels.jpg";
import krevende from "../static/gradering_krevende.jpg";
import ekspert from "../static/gradering_ekspert.jpg";
import "./TellturArticle.scss";

function openUrl(prefix) {
  let url = window.location.href;
  if (url.indexOf("?") !== -1) {
    url = url.split("?")[0];
  }
  url = url.replace("#", "?path=");
  window.open(prefix + url);
}

function getGraderingImage(gradering) {
  switch (gradering.toLowerCase()) {
    case "enkel":
      return enkel;
    case "middels":
      return middels;
    case "krevende":
      return krevende;
    case "ekspert":
      return ekspert;
    default:
      break;
  }
}

class TellturArticle extends React.Component {
  constructor() {
    super();
    this.state = {
      linkShareOpen: false,
      linkCopied: false
    };

    this.onShareLinkClick = this.onShareLinkClick.bind(this);
    this.onShareLinkPopupClose = this.onShareLinkPopupClose.bind(this);
    this.onClipBoardBtnClick = this.onClipBoardBtnClick.bind(this);
  }

  onShareLinkClick() {
    this.setState({
      linkShareOpen: true,
      linkCopied: false
    });
  }

  onShareLinkPopupClose() {
    this.setState({
      linkShareOpen: false,
      linkCopied: false
    });
  }

  onClipBoardBtnClick() {
    this.setState({
      linkCopied: true
    });
  }

  displayInactiveWarning() {
    const { startdato, sluttdato } = this.props;

    const startDateParts = startdato && startdato.split(".");
    const endDateParts = sluttdato && sluttdato.split(".");

    const startDate =
      startdato &&
      new Date(+startDateParts[2], startDateParts[1] - 1, +startDateParts[0]).setHours(0, 0, 0, 0);

    const endDate =
      sluttdato &&
      new Date(+endDateParts[2], endDateParts[1] - 1, +endDateParts[0]).setHours(0, 0, 0, 0);

    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (startDate > currentDate || endDate < currentDate) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {
      title,
      content,
      image,
      provider,
      hoydemeter,
      lengde,
      startdato,
      sluttdato,
      poeng,
      friluftsrad,
      gradering,
      kommune,
      t
    } = this.props;

    console.log(lengde, hoydemeter);

    return (
      <div className="telltur--article">
        {gradering && (
          <img
            className="telltur--article-gradering-img"
            src={getGraderingImage(gradering)}
            alt=""
          />
        )}
        <div className="telltur--article--header-container">
          {title && title !== null && <h1>{title}</h1>}
          {friluftsrad && friluftsrad !== null && (
            <p className="telltur--article-friluftsrad">
              {friluftsrad}
              {kommune && kommune !== null && ", " + kommune}
            </p>
          )}
        </div>
        <p className="telltur--image">{image && <img src={image} alt="" />}</p>
        <p className="articleProvider">{provider}</p>
        <div className="telltur-article-flex-box">
          <div className="telltur-article-meta-cols">
            <span className="telltur-article--meta-cols-label">{t("length")}</span>
            <span className="telltur-article--meta-cols-value">
              {lengde === null || lengde === undefined ? "-" : lengde} km
            </span>
          </div>
          <div className="telltur-article-meta-cols">
            <span className="telltur-article--meta-cols-label">{t("elevation")}</span>
            <span className="telltur-article--meta-cols-value">
              {hoydemeter === null || hoydemeter === undefined ? "-" : hoydemeter}
            </span>
          </div>
          <div className="telltur-article-meta-cols">
            <span className="telltur-article--meta-cols-label">{t("points")}</span>
            <span className="telltur-article--meta-cols-value">{poeng}</span>
          </div>
        </div>
        {this.displayInactiveWarning() && (
          <div className="telltur-article--notification">
            <p>{t("InactiveTrip")}</p>
          </div>
        )}
        <div className="content" dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
        <div className="telltur-article--share">
          <button
            className="btn btn-link share-facebook"
            onClick={() => openUrl("https://www.facebook.com/sharer.php?u=")}
          >
            <Icon name="facebook" />
            <span> Del på facebook</span>
          </button>
          <button className="btn btn-link share-link" onClick={this.onShareLinkClick}>
            <Icon name="share" />
            <span> Del med link</span>
          </button>
          <button className="btn btn-link print" onClick={() => window.print()}>
            <Icon name="print" />
            <span> Skriv ut </span>
          </button>
        </div>
        {(startdato || sluttdato) && (
          <div className="telltur-article-flex-box time-info">
            <div>
              {startdato && (
                <React.Fragment>
                  <span className="telltur-article--meta-cols-label">{t("startDate")}</span>
                  <span className="telltur-article--meta-cols-value">{startdato}</span>
                </React.Fragment>
              )}
            </div>
            <div className="float-right">
              {sluttdato && (
                <React.Fragment>
                  <span className="telltur-article--meta-cols-label">{t("endDate")}</span>
                  <span className="telltur-article--meta-cols-value">{sluttdato}</span>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {this.state.linkShareOpen && (
          <PopupBox onClose={this.onShareLinkPopupClose} closeBtnText={t("copyLinkCloseBtn")}>
            <React.Fragment>
              <p>{t("copyLink")}</p>
              <div className="telltur-article--share-container">
                <input
                  className="telltur-article--sharelink"
                  type="text"
                  readOnly
                  id="telltur--sharelink"
                  value={window.location.href}
                  onClick={e => e.target.select()}
                />
                <CopyToClipboard text={window.location.href}>
                  <button
                    className={[
                      "btn",
                      "telltur-article--clipboard-btn",
                      this.state.linkCopied ? " link-copied" : ""
                    ].join(" ")}
                    onClick={this.onClipBoardBtnClick}
                  >
                    {this.state.linkCopied ? t("linkCopied") : t("copyLinkBtn")}
                  </button>
                </CopyToClipboard>
              </div>
            </React.Fragment>
          </PopupBox>
        )}
      </div>
    );
  }
}

TellturArticle.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  provider: PropTypes.string,
  lengde: PropTypes.string,
  startdato: PropTypes.string,
  sluttdato: PropTypes.string,
  poeng: PropTypes.string,
  friluftsrad: PropTypes.string,
  gradering: PropTypes.string,
  kommune: PropTypes.string,
  hoydemeter: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default provideMapState(translate("TellturArticle")(TellturArticle));
