import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import PopupBox from "../components/PopupBox";
import { withRouter } from "react-router";
import { validateProfile } from "../utils/profile";

import "./ProfileValidator.scss";

class ProfileValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayProfileUpdatePopup: false
    };
    this.onDisplayProfilePopupClose = this.onDisplayProfilePopupClose.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;

    if (user && user.familymembers) {
      const mainMember = user.familymembers.find(f => f.is_main_familymember === true);
      var validProfile = validateProfile(user, mainMember);

      if (!validProfile.valid) {
        this.setState({ displayProfileUpdatePopup: true });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user } = this.props;
    if (!user && nextProps.user && nextProps.user.familymembers) {
      const mainMember = nextProps.user.familymembers.find(f => f.is_main_familymember === true);
      var validProfile = validateProfile(nextProps.user, mainMember);

      if (!validProfile.valid) {
        this.setState({ displayProfileUpdatePopup: true });
      }
    }
  }

  onDisplayProfilePopupClose = () => {
    this.setState({ displayProfileUpdatePopup: false });
    localStorage.setItem("telltur_profilePopupShown", "true");
    this.props.history.push("/minprofil");
  };

  render() {
    const { displayProfileUpdatePopup } = this.state;
    const { t } = this.props;

    return (
      <div className="telltur-profile-validator-wrapper">
        {displayProfileUpdatePopup && (
          <PopupBox onClose={this.onDisplayProfilePopupClose} closeBtnText={"Oppdater profil"}>
            <div className="telltur-profile-update--container">
              <h3>{t("Welcome")}</h3>
              <p>{t("ReminderText1")}</p>
              <p>{t("ReminderText2")}</p>
            </div>
          </PopupBox>
        )}
      </div>
    );
  }
}

ProfileValidator.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(translate("ProfileValidator")(ProfileValidator));
