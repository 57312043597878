import request from "superagent";
import { getSessionToken } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

const readAny = (
  themeUuid,
  columns,
  editable,
  onlyOwned,
  objectId,
  fkColumn,
  fkColumnIds,
  filter,
  start,
  limit,
  srid,
  callback
) => {
  const params = {
    theme_uuid: themeUuid,
    columns,
    editable,
    only_owned: onlyOwned,
    object_id: objectId,
    fk_column: fkColumn,
    fk_column_ids: fkColumnIds,
    filter,
    start,
    limit,
    srid
  };

  request
    .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny")
    .send({ request: params })
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .end((err, res) => {
      callback(err, res);
    });
};

const readFiltered = (themeUuid, columns, filter, callback) => {
  return readAny(
    themeUuid,
    columns,
    false,
    false,
    null,
    null,
    null,
    filter,
    0,
    1000,
    config.mapProjCode,
    callback
  );
};

const readChildren = (themeUuid, fkColumn, columns, filter, editable, fkColumnId, callback) => {
  let params = {
    theme_uuid: themeUuid,
    fk_column: fkColumn,
    fk_column_id: fkColumnId,
    columns,
    editable,
    filter
  };

  request
    .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadChildren")
    .send(params)
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .end((err, res) => {
      callback(err, res);
    });
};

const save = (themeUuid, data, callback) => {
  request
    .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/Save")
    .send({
      data,
      theme_uuid: themeUuid
    })
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .end((err, res) => {
      callback(err, res);
    });
};

const remove = (themeUuid, id, callback) => {
  request
    .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/Delete")
    .send({
      id,
      theme_uuid: themeUuid
    })
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .end((err, res) => {
      callback(err, res);
    });
};

export { readFiltered, readChildren, save, remove, readAny };
