import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import TranslatedServerError from "./TranslatedServerError";

import regTripImg from "../static/telefon_telltur_BF-red.png";

const generateMemberOptions = members => {
  if (members) {
    return members.map(m => {
      return (
        <option key={m.id} value={m.id}>
          {m.nick_name}
        </option>
      );
    });
  }
};

const RegisterTripForm = props => {
  const {
    t,
    members,
    registrationError,
    tripcode,
    tripdate,
    selectedMemberId,
    validationErrors,
    handleChange,
    handleSubmit,
    handleCancel,
    isLoading
  } = props;
  const memberOptions = generateMemberOptions(members);
  return (
    <div>
      <h1>{t("RegisterTrip")}</h1>
      <span className="tell-tur--register-trip-form">
        <span>
          <p>
            {t("SeeRegisteredTripsOn")}{" "}
            <Link to="/mineturer">{t("ThisPage")}</Link>
          </p>
          <form onSubmit={handleSubmit} className="tell-tur-form">
            {members && members.length > 1 && (
              <span className="tell-tur-form--control">
                <label>{t("RegisterTripAsMember")}</label>
                <select
                  name="selectedMemberId"
                  value={selectedMemberId}
                  onChange={handleChange}
                >
                  {memberOptions}
                </select>
                <span className="error">
                  {validationErrors.selectedMemberId}
                </span>
              </span>
            )}

            <span className="tell-tur-form--control">
              <label>{t("TripCode")}</label>
              <input
                name="tripcode"
                type="text"
                value={tripcode}
                onChange={handleChange}
              />
            </span>
            <span className="error">{validationErrors.tripcode}</span>
            <span className="tell-tur-form--control">
              <label>{t("TripDate")}</label>
              <input
                name="tripdate"
                type="date"
                value={tripdate}
                onChange={handleChange}
              />
            </span>
            <span className="error">{validationErrors.tripdate}</span>
            {registrationError && (
              <TranslatedServerError
                error={registrationError}
                cssClass={"error u-margin-top-small"}
              />
            )}
            <span className="error u-margin-top-small">
              {validationErrors.general}
            </span>
            <div className="tell-tur-form--button-group">
              <button className="btn btn-link" onClick={handleCancel}>
                {t("Cancel")}
              </button>
              <button
                disabled={isLoading}
                className="btn btn-primary"
                type="submit"
              >
                {t("Save")}
              </button>
            </div>
          </form>
        </span>
        <img src={regTripImg} alt="" />
      </span>
    </div>
  );
};

RegisterTripForm.propTypes = {
  members: PropTypes.array,
  t: PropTypes.func,
  registrationError: PropTypes.object,
  validationErrors: PropTypes.object.isRequired,
  tripcode: PropTypes.string.isRequired,
  tripdate: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedMemberId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};
export default translate("RegisterTripForm")(RegisterTripForm);
