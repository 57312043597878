import React, { useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { ArticleWindow, useArticle } from "@avinet/react-article-module";
import { useTranslate } from "react-translate";
import Title from "../components/Title";
import TellturArticle from "../components/TellturArticle";
import DestinationStats from "../containers/DestinationStats";
import Icon from "../components/Icon";
import StiLayer from "../components/Map/StiLayer";
import "./ArticleDetailView.scss";

function ArticleDetailView() {
  const t = useTranslate("ArticleDetailView");
  const history = useHistory();
  const { state } = useLocation();
  const { sourceId, articleId } = useParams();
  const aid = sourceId + "_" + articleId;
  const { article } = useArticle(aid);

  const onClose = useCallback(() => {
    const baseUrl = "/alleturmaal";
    history.push(baseUrl + (state?.activeSources ? "/" + state.activeSources : ""));
  }, [history, state]);

  return (
    <div className="article-detail-view--container" id="scrollPane">
      <Title title={t("title")} />
      <ArticleWindow articleComponent={TellturArticle} id={aid} maxZoom={14} hideOpenExternalBtn>
        <div className="article-detail-view--children">
          <button className="article-detail-view--close-btn btn" onClick={onClose}>
            <Icon name="cross" />
          </button>
          {article && <DestinationStats articleId={parseInt(articleId)} />}
          <StiLayer articleId={articleId} />
        </div>
      </ArticleWindow>
    </div>
  );
}

export default ArticleDetailView;
