import request from "superagent";
import { getSessionToken, authReducerActions } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

// ------------------------------------
// Constants
// ------------------------------------
export const JOINING_COMPETITION = "JOINING_COMPETITION";
export const COMPETITION_JOINED_SUCCESS = "COMPETITION_JOINED_SUCCESS";
export const COMPETITION_JOINED_ERROR = "COMPETITION_JOINED_ERROR";

export const UNJOINING_COMPETITION = "UNJOINING_COMPETITION";
export const COMPETITION_UNJOINED_SUCCESS = "COMPETITION_UNJOINED_SUCCESS";
export const COMPETITION_UNJOINED_ERROR = "COMPETITION_UNJOINED_ERROR";

export const READ_JOINED_COMPETITIONS = "READ_JOINED_COMPETITIONS";
export const JOINED_COMPETITIONS_READ_SUCCESS = "JOINED_COMPETITIONS_READ_SUCCESS";
export const JOINED_COMPETITIONS_READ_ERROR = "JOINED_COMPETITIONS_READ_ERROR";

export const READ_UNIQUE_JOINED_COMPETITIONS = "READ_UNIQUE_JOINED_COMPETITIONS";
export const UNIQUE_JOINED_COMPETITIONS_READ_SUCCESS = "UNIQUE_JOINED_COMPETITIONS_READ_SUCCESS";
export const UNIQUE_JOINED_COMPETITIONS_READ_ERROR = "UNIQUE_JOINED_COMPETITIONS_READ_ERROR";

export const READ_COMPETITION_RESULTS = "READ_COMPETITION_RESULTS";
export const COMPETITION_RESULTS_READ_SUCCESS = "COMPETITION_RESULTS_READ_SUCCESS";
export const COMPETITION_RESULTS_READ_ERROR = "COMPETITION_RESULTS_READ_ERROR";

export const RESET_STATE = "RESET_STATE";

// ------------------------------------
// Actions
// ------------------------------------

const joiningCompetition = () => ({
  type: JOINING_COMPETITION
});

const competitionJoinedSuccess = success => ({
  type: COMPETITION_JOINED_SUCCESS,
  payload: {
    success
  }
});

const competitionJoinedError = error => ({
  type: COMPETITION_JOINED_ERROR,
  payload: {
    error
  }
});

const unjoiningCompetition = () => ({
  type: UNJOINING_COMPETITION
});

const competitionUnjoinedSuccess = success => ({
  type: COMPETITION_UNJOINED_SUCCESS,
  payload: {
    success
  }
});

const competitionUnjoinedError = error => ({
  type: COMPETITION_UNJOINED_ERROR,
  payload: {
    error
  }
});

const readingJoinedCompetitions = () => ({
  type: READ_JOINED_COMPETITIONS
});

const joinCompetitionReadSuccess = (competitions, success) => ({
  type: JOINED_COMPETITIONS_READ_SUCCESS,
  payload: {
    competitions,
    success
  }
});

const joinCompetitionReadError = error => ({
  type: JOINED_COMPETITIONS_READ_ERROR,
  payload: {
    error
  }
});

const readingUniqueJoinedCompetitions = () => ({
  type: READ_JOINED_COMPETITIONS
});

const uniqueJoinedCompetitionReadSuccess = (competitions, success) => ({
  type: JOINED_COMPETITIONS_READ_SUCCESS,
  payload: {
    competitions,
    success
  }
});

const uniqueJoinedCompetitionReadError = error => ({
  type: JOINED_COMPETITIONS_READ_ERROR,
  payload: {
    error
  }
});

const readingCompetitionResults = () => ({
  type: READ_COMPETITION_RESULTS
});

const competitionResultsReadSuccess = (results, success) => ({
  type: COMPETITION_RESULTS_READ_SUCCESS,
  payload: {
    results,
    success
  }
});

const competitionResultsReadError = error => ({
  type: COMPETITION_RESULTS_READ_ERROR,
  payload: {
    error
  }
});

export const resetState = () => ({
  type: RESET_STATE
});

// ------------------------------------
// Action creators
// ------------------------------------
export function joinCompetition(competitionId, userId) {
  return dispatch => {
    dispatch(joiningCompetition());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Competition.asmx/JoinCompetition")
      .send({ competition_id: competitionId, user_id: userId })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(competitionJoinedError({ message: "Error returned from service" }, null));
        }

        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            competitionJoinedError(
              response.exception || {
                message: "Received an error from server"
              },
              null
            )
          );
          return;
        }
        dispatch(competitionJoinedSuccess(true));
      });
  };
}

export function unjoinCompetition(id) {
  return dispatch => {
    dispatch(unjoiningCompetition());
    request
      .post(config.adaptiveUrl + "WebServices/telltur/Competition.asmx/UnjoinCompetition")
      .send({ joined_competition_id: id })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(competitionUnjoinedError({ message: "Error returned from service" }, null));
        }

        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            competitionUnjoinedError(
              response.exception || {
                message: "Received an error from server"
              },
              null
            )
          );
          return;
        }
        dispatch(competitionUnjoinedSuccess(true));
      });
  };
}

export function readJoinedCompetitions() {
  return dispatch => {
    dispatch(readingJoinedCompetitions());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Competition.asmx/GetJoinedCompetitions")
      .send()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(joinCompetitionReadError({ message: "Error returned from service" }, null));
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            joinCompetitionReadError(
              response.exception || {
                message: "Received an error from server"
              },
              null
            )
          );
          return;
        }
        dispatch(joinCompetitionReadSuccess(response.records, true));
      });
  };
}

export function readUniqueJoinedCompetitions() {
  return dispatch => {
    dispatch(readingUniqueJoinedCompetitions());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Competition.asmx/GetUniqueJoinedCompetitions")
      .send()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(
            uniqueJoinedCompetitionReadError({ message: "Error returned from service" }, null)
          );
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            uniqueJoinedCompetitionReadError(
              response.exception || {
                message: "Received an error from server"
              },
              null
            )
          );
          return;
        }
        const competitions = response.records.sort((a, b) => b.id - a.id);

        dispatch(uniqueJoinedCompetitionReadSuccess(competitions, true));
      });
  };
}

export function readCompetitionResults(id) {
  return dispatch => {
    dispatch(readingCompetitionResults());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Competition.asmx/GetCompetitionResults")
      .send({ competition_id: id })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(competitionResultsReadError({ message: "Error returned from service" }, null));
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            competitionResultsReadError(
              response.exception || {
                message: "Received an error from server"
              },
              null
            )
          );
          return;
        }
        var results = response.records.sort((a, b) => b.points - a.points);
        dispatch(competitionResultsReadSuccess(results, null));
      });
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  joining: false,
  unjoining: false,
  success: false,
  error: undefined,
  competitions: undefined,
  results: undefined
};

export function joinCompetitionReducer(state = initialState, action) {
  switch (action.type) {
    case JOINING_COMPETITION:
      return Object.assign({}, state, {
        joining: true,
        error: undefined
      });
    case COMPETITION_JOINED_SUCCESS:
      return Object.assign({}, state, {
        joining: false,
        success: true
      });
    case COMPETITION_JOINED_ERROR:
      return Object.assign({}, state, {
        joining: false,
        error: action.payload.error
      });
    case UNJOINING_COMPETITION:
      return Object.assign({}, state, {
        unjoining: true,
        error: undefined
      });
    case COMPETITION_UNJOINED_SUCCESS:
      return Object.assign({}, state, {
        unjoining: false,
        success: true
      });
    case COMPETITION_UNJOINED_ERROR:
      return Object.assign({}, state, {
        unjoining: false,
        error: action.payload.error
      });
    case READ_JOINED_COMPETITIONS:
      return Object.assign({}, state, {
        loading: true,
        error: undefined
      });
    case JOINED_COMPETITIONS_READ_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        competitions: action.payload.competitions
      });
    case JOINED_COMPETITIONS_READ_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error
      });
    case READ_UNIQUE_JOINED_COMPETITIONS:
      return Object.assign({}, state, {
        loading: true,
        error: undefined
      });
    case UNIQUE_JOINED_COMPETITIONS_READ_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        competitions: action.payload.competitions
      });
    case UNIQUE_JOINED_COMPETITIONS_READ_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error
      });
    case READ_COMPETITION_RESULTS:
      return Object.assign({}, state, {
        loading: true,
        error: undefined
      });
    case COMPETITION_RESULTS_READ_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        results: action.payload.results
      });
    case COMPETITION_RESULTS_READ_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error
      });

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
