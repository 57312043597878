import React from 'react';
import PropTypes from 'prop-types';

class YearSelector extends React.Component {
  static propTypes = {
    fromYear: PropTypes.number.isRequired,
    onYearSelected: PropTypes.func.isRequired,
    infoText: PropTypes.string
  };
  constructor(props) {
    super(props);

    const thisYear = new Date().getFullYear();

    this.state = {
      thisYear: thisYear,
      selectedYear: thisYear
    };
  }

  renderYears() {
    const { thisYear } = this.state;
    const { fromYear } = this.props;

    let options = [];
    for (let i = thisYear; i >= fromYear; i--) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  }

  componentDidMount() {
    this.props.onYearSelected(this.state.selectedYear);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedYear !== prevState.selectedYear) {
      this.props.onYearSelected(this.state.selectedYear);
    }
  }

  handleChange = evt => {
    this.setState({ selectedYear: evt.target.value });
  };

  render() {
    const { selectedYear } = this.state;
    const { infoText } = this.props;

    return (
      <span className="tell-tur-form--control">
        {infoText && (
          <div className="form-control--infoText" dangerouslySetInnerHTML={{ __html: infoText }} />
        )}
        <select value={selectedYear} onChange={this.handleChange}>
          {this.renderYears()}
        </select>
      </span>
    );
  }
}

export default YearSelector;
