export default {
  locale: "no",

  DefaultMap: {
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut"
  },

  ProfileValidator: {
    Welcome: "Velkommen",
    ReminderText1:
      "Det ser ut til at vi mangler litt informasjon om deg. Vennligst sjekk at din profil inneholder korrekte opplysninger, og at du er knyttet til riktig friluftsråd.",
    ReminderText2:
      "Denne påminnelsen vil vises ved innlogging frem til du har oppdatert profilen din."
  },

  LoginPage: {
    LogIn: "Logg inn",
    Username: "E-postadresse",
    Password: "Passord",
    ForgotPassword: "Glemt passord?",
    NewUser: "Ny på TellTur?",
    RegisterHere: "Registrer deg her",
    Error: "Login feilet. Vennligst sjekk at e-post og passord er korrekt",
    sessionExpired: "Din innlogging er utløpt. Vennligst logg inn på nytt."
  },

  UserRegistrationPage: {
    UsernameTooShort: "Brukernavn er for kort",
    PasswordsDoNotMatch: "Passordene er ikke like",
    PasswordIsTooShort: "Passordet er for kort",
    FirstnameNotValid: "Fornavn er ikke gyldig",
    SurnameNotValid: "Etternavn er ikke gyldig",
    MobileNotValid: "Mobilnummer er ikke gyldig",
    EmailAddressNotValid: "E-postadresse er ikke gyldig",
    MunicipalityNotValid: "Bostedskommune er ikke gyldig",
    YearOfBirthNotValid: "Fødselsår er ikke gyldig",
    ToLogin: "Gå til innlogging",
    SelectedFriluftsraadIdIsMissing: "Vennligst velg et friluftsråd",
    SelectedCompanyIdIsInvalid: "Valgt bedrift er ugyldig"
  },

  UserRegistrationForm: {
    Register: "Registrer meg",
    Username: "* Brukernavn/kallenavn (dette vil være synlig på resultatlister)",
    Password: "* Passord (benyttes for å logge inn på TellTur)",
    PasswordRepeat: "* Gjenta passordet",
    Firstname: "* Fornavn",
    Surname: "* Etternavn",
    Mobile: "* Mobiltelefonnummer",
    Email: "* E-postadresse",
    Municipality: "* Bostedskommune",
    YearOfBirth: "* Fødselsår",
    SelectedFriluftsraad:
      "* Velg friluftsråd. Alle deltagere i Telltur må ha tilhørighet til et friluftsråd, dette er for at arrangørene skal kunne hente ut statistikker til de ulike konkurransene. Velg friluftsrådet for regionen der du regner med å gå de fleste turene dine i år",
    AddCompany: "Tilknytt firma (valgfritt)",
    ChooseFriluftsraad: "Velg friluftsråd",
    ChooseMunicipality: "Velg kommune"
  },

  ResetPasswordPage: {
    Email: "E-postadresse",
    SendNewPassword: "Send nytt passord",
    Cancel: "Avbryt",
    CouldNotResetPassword:
      "Kunne ikke nullstille passord. Vennligst sjekk at e-postadressen er riktig",
    PasswordResetInfoText:
      "Nytt passord er sendt på mail. Dersom du ikke mottar den, sjekk om den kan ligge i søppelpost-filteret",
    GoBack: "Gå tilbake til innlogging"
  },

  ChangePasswordPage: {
    CurrentPassword: "Nåværende passord",
    NewPassword: "Nytt passord",
    RepeatPassword: "Gjenta nytt passord",
    Cancel: "Avbryt",
    SaveNewPassword: "Lagre nytt passord",
    PasswordChanged: "Ditt passord har blitt endret",
    GoBack: "Tilbake til min profil",
    CouldNotChangePassword: "Kan ikke endre passordet"
  },

  MyProfilePage: {
    ChangePassword: "Endre passord",
    EditFamilyMembers: "Vis ekstra turgåere",
    DeleteAccount: "Slett konto",
    ConfirmDelete: "Jeg forstår, slett brukerkontoen min",
    SelectedFriluftsraadIsInvalid: "Valgt friluftsråd er ikke gyldig",
    SelectFriluftsraad: "Velg friluftsråd",
    ConnectedToFriluftsraad: "Tilknyttet friluftsråd",
    UsernameTooShort: "Brukernavn er for kort",
    FirstnameNotValid: "Fornavn er ikke gyldig",
    SurnameNotValid: "Etternavn er ikke gyldig",
    MobileNotValid: "Mobilnummer er ikke gyldig",
    EmailAddressNotValid: "E-postadresse er ikke gyldig",
    MunicipalityNotValid: "Bostedskommune er ikke gyldig",
    YearOfBirthNotValid: "Fødselsår er ikke gyldig",
    ConnectedToCompany: "Tilknytt bedrift (valgfritt)",
    Unjoin: "Ingen bedrift",
    General:
      "Det ser ut til at vi mangler litt informasjon om deg. Vennligst sjekk at din profil inneholder korrekte opplysninger, og at du er knyttet til riktig friluftsråd. Denne påminnelsen vil vises ved innlogging frem til du har oppdatert profilen din.",
    Agree: "Ja, jeg tillater at Friluftsrådene kan sende meg e-post om TellTur og turkonkurranser."
  },

  EditableDropdown: {
    Cancel: "Avbryt",
    Save: "Lagre"
  },

  MyProfileForm: {
    Save: "Lagre",
    Nickname: "Brukernavn/kallenavn (dette vil være synlig på resultatlister)",
    Firstname: "Fornavn (vil kun være synlig for deg og friluftsrådenes ansatte)",
    Surname: "Etternavn (vil kun være synlig for deg og friluftsrådenes ansatte)",
    Mobile: "Mobiltelefonnummer",
    Email: "E-postadresse",
    Municipality: "Bostedskommune",
    YearOfBirth: "Fødselsår",
    ReminderText1:
      'Du har endret bostedskommune, kontroller at du er tilknyttet riktig friluftsråd etter endringen. Friluftsrådet for din kommune ligger øverst i listen under feltet "Tilknyttet friluftsråd".'
  },

  ArticleList: {
    NoResults: "Ingen turer funnet",
    NoResultsTryChange: "Prøv å velg andre turtyper eller flytte på kartet",
    Loading: "Laster turer"
  },

  AddMemberPage: {
    Nickname: "Kallenavn",
    Year: "Fødselsår",
    AddNewUser: "Legg til",
    Delete: "Slett",
    FriluftsraadNotValid: "Kan ikke finne gyldig friluftsråd-id. Vennligst oppdater din profil"
  },

  RegisterTripForm: {
    RegisterTrip: "Registrer en tur",
    SeeRegisteredTripsOn: "Du kan se dine registrerte turer på ",
    ThisPage: "denne siden",
    TripCode: "* Turkode (koden du fant på turmålet)",
    TripDate: "* Turdato",
    Cancel: "Avbryt",
    Save: "Lagre",
    RegisterTripAsMember: "* Registrer tur som bruker"
  },

  RegisterTripPage: {
    NotLoggedIn: "Du må være logget inn for å registrere turer",
    TripCodeNotValid: "Turkode er ikke gyldig",
    TripDateNotValid: "Turdato er ikke gyldig. Vennligst oppgi dato på formatet dd.mm.yyyy",
    SelectAMember: "Vennligst velg et familiemedlem",
    CantRegisterTripsFromLastYear: "Kan ikke registrere turer fra ifjor",
    CantRegisterTripsInFuture: "Kan ikke registrere turer frem i tid",
    Title: "Registrer tur"
  },

  MemberList: {
    Trips: "Turer"
  },

  ArticleView: {
    showFriluftsrad: "Vis friluftsråd",
    showTipBox: "Vis tipsboks",
    tipboxContent:
      "<h3>Slik bruker du kartløsningen</h3>" +
      "<p>Klikk på den grønne knappen med kompasspilen for å zoome inn til stedet der du befinner deg akkurat nå. Dersom dette ikke virker må du tillate bruk av stedstjenester i nettleseren. På mobil finnes dette gjerne under Innstillinger.</p>" +
      "<p>Artikkellisten viser forslag til turmål basert på kartutstnittet. Når du zoomer i kartet, med musescroll eller fingre, vil listen oppdateres automatisk.</p>" +
      "<p>Klikk på det blå feltet over artiklene merket «{0}», og sett hake kun ved de turtyper du ønsker å vise.</p>" +
      "<p>Bruk gjerne søkefeltet helt øverst til høyre for å finne informasjon om kjente turmål.</p>" +
      '<p>Disse tipsene kan hentes frem igjen når du ønsker ved å klikke på den grønne «Vis tipsboks»-knappen på kartet."</p>',
    closeTipBox: "Ok, lukk tipsboks",
    dontshowTipBox: "Ikke vis igjen",
    sourceselectorText1: "1 valgt turtype",
    sourceselectorText2: "{0} valgte turtyper",
    Title: "Alle turmål"
  },

  ArticleDetailView: {
    title: "TellTur: Turmål"
  },

  NavBar: {
    MyProfile: "Min profil",
    LogOut: "Logg ut"
  },

  TellturSearch: {
    tellturSearchPlaceholder: "Søk etter turmål her",
    tellturSearchPlaceholderSelected: "Søk etter {{sources}}"
  },

  DestinationStats: {
    locale: "no",
    lastVisitors: "Siste fem (dato)",
    topVisitors: "Topp fem (besøk)",
    visitorStatistics: "Besøksstatistikk",
    noVisitors: "Ingen besøkende registrert"
  },

  InstafeedComponent: {
    locale: "no",
    instagramHeader: "Følg {0} på Instagram",
    instafeedEmpty:
      "Bli den første til å poste bilde fra {0} på Instagram. Tag bildet ditt med #{1} og #{2}!"
  },

  ResultsPage: {
    MostPoints: "Flest poeng",
    Points: "Poeng",
    CompanyName: "Bedrift/lag",
    Participants: "Deltagere",
    PointsNorm: "Poeng",
    TotalPoints: "Total poeng",
    Nickname: "Brukernavn",
    Search: "søk på brukernavn",
    AllFr: "Alle friluftsråd",
    MyFr: "Mitt friluftsråd",
    AllCompanies: "Alle bedrifter",
    MyCompany: "Min bedrift",
    UniqueDestinations: "Unike turmål",
    Destination: "Turmål",
    Friluftsraad:
      "<p>Her kan du se statistikk for alle turene i {0} i {1}.<br>" +
      "Dersom du ønsker å delta under et annet friluftsråd kan du endre dette inne på din brukerprofil.</p>",
    Company:
      "<p>Statistikk for bedriftskonkuransen til {0} i {1}.<br>" +
      "Dersom du ønsker å knytte deg til en annen bedrift kan du endre dette inne på din brukerprofil.</p>"
  },

  ResultCard: {
    MostPoints: "Flest poeng",
    Points: "Poeng",
    UniqueDestinations: "Unike turmål",
    Destination: "Turmål",
    ChooseYear: "Velg år:"
  },

  TranslatedServerError: {
    UserExists: 'Bruker med brukernavnet "{{username}}" finnes allerede. ',
    InvalidUser: "Ugyldige brukerdata sendt inn. Forsøk å logg inn igjen på nytt.",
    InvalidTurmaal: "Kunne ikke finne turmål med denne koden.",
    InvalidOwner: "Du må være eier av objektet som skal endres. Ta kontakt med administrator.",
    EmailInUse: "Det er registrert en annen bruker med denne e-postadressen",
    TurmaalNotActive: "Denne datoen er utenfor konkurranseperioden for dette turmålet.",
    TURMAAL_ALREADY_REGISTERED_TODAY:
      "Du har allerede en registrert tur på dette turmålet på den valgte datoen",
    DATO_ERROR: "Datoformat kunne ikke håndteres",
    INVALID_FAMILY_MEMBER: "Ugyldig familiemedlem valgt, velg familiemedlem igjen og prøv på nytt",
    cannot_change_frilufstrad_active_competitions:
      "Du er meldt på én eller flere aktive konkurranser i et annet friluftsråd. For å kunne bytte friluftsråd må du først melde deg av aktive konkurranser."
  },

  MyTripsContainer: {
    NoTrips: "Ingen turer registrert"
  },

  TellturArticle: {
    locale: "nb-NO",
    InactiveTrip: "MERK: Denne turen er ikke i sesong for poengtelling nå",
    copyLinkBtn: "Kopier lenke",
    copyLink:
      "Kopier nettadressen nedenfor for å dele turmålet med dine venner via chat eller e-post.",
    linkCopied: "Lenke kopiert",
    copyLinkCloseBtn: "Lukk",
    elevation: "Høydemeter",
    length: "Lengde",
    points: "Poeng",
    startDate: "Startdato",
    endDate: "Sluttdato"
  },

  TripList: {
    locale: "no",
    Date: "Dato",
    Trip: "Turmål",
    Points: "Poeng",
    DeleteTrip: "Slett tur"
  },

  IndexPage: {
    ActiveCompetitions: "Det er {0} aktive temakonkurranser hos ditt friluftsråd",
    JoinNow: "delta nå!",
    FindTrip: "Finn et turmål",
    CheckCompetitions: "Se konkurranser",
    Description1:
      "er friluftsrådenes elektroniske postkassetrim. Her kan du finne turmål og registrere resultatene dine. Konkurrer med deg selv eller med andre turgåere om å nå flere mål!",
    Description2: "for å se hvilke turmål som finnes, eller gå til ditt nærmeste friluftsråd og",
    Description3:
      'Telltur finner du i appen "Norgeskart friluftsliv" som er gratis for deltakere på Telltur.',
    Description4: "",
    UseMapLink: "Bruk kartet ",
    GoToFrLink: " les om hvilke konkurranser du kan delta i.",
    GoToAboutLink: "Mer om bruk av appen her.",
    GoodLuck: "Lykke til og god tur!",
    Covid1:
      "Covid-19 restriksjoner: På grunn av smittefare frarådes bruk av postkassen på turmålet. Du kan i stedet registrere turen din her på nettsiden eller via egen mobilapp.",
    Covid2:
      "Merk også at Friluftsrådene stenger hytter og ber alle unngå samlingsplasser som gapahuker, rasteplasser og lignende.",
    readMore: "Les mer om dette her >",
    dontshowTipBox: "Ikke vis igjen",
    closeTipBox: "ok, lukk tipsboks"
  },

  PageView: {
    Welcome: "Velkommen",
    ActiveCompetitions: "Det pågar {0} temakonkurranser i ditt område",
    ReminderText1:
      "Det ser ut til at vi mangler litt informasjon om deg. Vennligst sjekk at din profil inneholder korrekte opplysninger, og at du er knyttet til riktig friluftsråd.",
    ReminderText2:
      "Denne påminnelsen vil vises ved innlogging frem til du har oppdatert profilen din."
  },

  Competitions: {
    CompetitionsTheme: "Temakonkurranser",
    Description:
      "I denne boksen finner du en liste over konkurransene i {0}. " +
      "Klikk på pilene for å lese mer om hver. De konkurranser du kan delta i vil ha en grønn " +
      "påmeldingsknapp nederst.",
    LinkToResults: "Dine resultater.",
    LinkToYourProfile: "Dine registrerte turer.",
    NoCompetitions: "Friluftsrådet har ingen pågående temakonkurranser"
  },

  CompetitionsList: {
    locale: "nb-NO",
    Municipality: "Bostedskommune: ",
    YearOfBirthFrom: "Fødselsår fra: ",
    YearOfBirthTo: "Fødselsår til: ",
    StartDate: "Startdato: ",
    EndDate: "Sluttdato: ",
    Participate: "Meld på",
    terms: "Betingelser",
    destinations: "Turmål",
    points: "poeng"
  },

  CompetitionResults: {
    locale: "nb-NO",
    Started: "Startdato",
    Ends: "Sluttdato",
    Title: "Resultater for temakonkurranser",
    CompetitionDescription:
      "Nedenfor vises resultatlister for temakonkurransene du deltar i. Oversikt over disse med mulighet for påmelding finner du på ",
    LinkToFriluftsrad: "ditt friluftsråds informasjonsside",
    NoUniqueJoinedCompetitions: "Du er ikke påmeldt noen temakonkurranser",
    Nickname: "Brukernavn",
    Points: "Poeng"
  },

  JoinCompetitionForm: {
    ConfirmJoin: "Bekreft påmelding",
    ChooseMember: "Velg brukeren du ønsker å melde på konkurransen: ",
    Success: "Du er nå påmeldt konkurransen",
    ButtonCancel: "Avbryt",
    ButtonClose: "Lukk",
    ButtonConfirm: "Meld på",
    invalid_user: "Ugyldig bruker",
    invalid_family_member: "Valgt bruker ble ikke funnet.",
    compotition_not_found: "Konkurransen du prøver å melde deg på finnes ikke.",
    competition_wrong_friluftsrad:
      "Konkurransen tilhører ikke ditt friluftsråd. Du kan derfor ikke melde deg.",
    already_joined_competition: "Du er allerede påmeldt denne konkurransen",
    competition_not_active: "Konkurransen er ikke lenger aktiv",
    user_too_young: "Brukeren er for ung til å delta",
    user_too_old: "Brukeren er for gammel til å delta"
  },

  CompetitionPage: {
    title: "Dine temakonkurranser",
    Description:
      "Nedenfor vises de lokale temakonkurranser du og dine ekstra turgåere er påmeldt. Du kan " +
      "melde deg av ved å klikke på krysset til høyre for hver konkurranse. Les mer om hver " +
      "enkelt temakonkurranse ",
    LinkToFriluftsrad: "hos ditt friluftsråd",
    Note: "Merk at du fremdeles er med i TellTurkonkurransen selv om du melder deg av en temakonkurranse.",
    NoMemberCompetitions: "Du er ikke påmeldt noen temakonkurranser",
    CancelationConfirm: "Bekreft avmelding",
    CancelationDescription:
      "Når du melder deg av en temakonkurranse blir alle poeng du har samlet i denne konkurransen slettet. Dersom du ønsker å gjenoppta deltagelsen vil du måtte starte poengsankingen på nytt.",
    CancelationDescriptionNote:
      "De ordinære TellTurpoengene dine (i den nasjonale konkurransen) blir ikke berørt av avmeldingen.",
    ButtonCancel: "Avbryt",
    ButtonConfirm: "Meld av",
    invalid_user: "Ugyldig bruker",
    invalid_family_member: "Valgt bruker ble ikke funnet.",
    competition_not_found: "Konkurransen du prøver å melde deg på finnes ikke."
  }
};
