import { bindActionCreators as originalBindActionCreator } from 'redux';

const wrapAction = (action, reducerKey) => {
  return {
    ...action,
    type: reducerKey + '@@' + action.type
  };
};

const wrapDispatch = (dispatch, reducerKey) => {
  const wrappedDispatch = action => {
    let wrappedAction;
    if (typeof action === 'function') {
      wrappedAction = (globalDispatch, getState, extraArgument) => {
        return action(
          wrappedDispatch,
          getState,
          () => getState()[reducerKey],
          globalDispatch,
          reducerKey,
          extraArgument
        );
      };
    } else if (typeof action === 'object') {
      wrappedAction = wrapAction(action, reducerKey);
    }
    return dispatch(wrappedAction);
  };
  return wrappedDispatch;
};

const bindActionCreators = (actionCreators, dispatch, reducerKey) => {
  const wrappedDispatch = wrapDispatch(dispatch, reducerKey);
  return originalBindActionCreator(actionCreators, wrappedDispatch);
};

export default bindActionCreators;
