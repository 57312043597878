import React from "react";
import PropTypes from "prop-types";
import CompetitionsList from "./CompetitionsList";
import LoadingIndicator from "../../components/LoadingIndicator";
import { actions as digiThemeActions } from "../../utils/digiTheme";
import scopedBindActionCreators from "../../utils/scopedReducer";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { translate } from "react-translate";
import config from "../../config/TellTur";

import "./Competitions.scss";

const mapDispatchToProps = dispatch => {
  return {
    readCompetions: scopedBindActionCreators(
      digiThemeActions.readFiltered,
      dispatch,
      "competition"
    ),
    resetDigiTheme: scopedBindActionCreators(
      digiThemeActions.resetDigiTheme,
      dispatch,
      "competition"
    )
  };
};

const mapStateToProps = state => ({
  competitions: state.competition.records,
  loading: state.competition.loading
});

class Competitons extends React.Component {
  static propTypes = {
    readCompetions: PropTypes.func.isRequired,
    resetDigiTheme: PropTypes.func.isRequired,
    friluftsradUuid: PropTypes.string,
    competitions: PropTypes.array,
    t: PropTypes.func.isRequired,
    friluftsrad: PropTypes.string,
    loading: PropTypes.bool
  };

  componentDidMount() {
    this.readFriluftsradCompetitions();
  }

  componentWillUnmount() {
    this.props.resetDigiTheme();
  }

  readFriluftsradCompetitions() {
    const { readCompetions, friluftsradUuid } = this.props;

    readCompetions(
      [
        "id",
        "title",
        "description",
        "image",
        "municipality",
        "year_of_birth_to",
        "year_of_birth_from",
        "start_date",
        "end_date",
        "show"
      ],
      {
        filterColumns: [
          {
            name: "_acl",
            netType: "Guid[]",
            value: [friluftsradUuid],
            comparisonOperator: "@>",
            logicalOperator: "AND"
          },
          {
            name: "show",
            netType: "boolean",
            value: true,
            comparisonOperator: "=",
            logicalOperator: "AND"
          }
        ],
        sortColums: [
          {
            name: "name"
          }
        ]
      },
      config.mapProjCode
    );
  }

  render() {
    const {
      friluftsrad,
      friluftsradUuid,
      loading,
      competitions,
      t
    } = this.props;
    return (
      <div className={"tell-tur-competitions__wrapper"}>
        <React.Fragment>
          <div className="wrapper__description">
            <h3>{t("CompetitionsTheme")}</h3>
            <p>{t("Description").replace("{0}", friluftsrad)}</p>
            <Link className="btn-link" to="/resultater">
              {t("LinkToResults")}
            </Link>
            <Link className="btn-link" to="/minprofil">
              {t("LinkToYourProfile")}
            </Link>
          </div>
          <CompetitionsList
            competitions={competitions}
            friluftsradUuid={friluftsradUuid}
            loading={loading}
          />
        </React.Fragment>
        {loading && (
          <div className="wrapper__loader">
            <LoadingIndicator />
          </div>
        )}
        {!loading && competitions.length === 0 && (
          <div className="wrapper__no-competitions">{t("NoCompetitions")}</div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate("Competitions")(Competitons))
);
