import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "react-translate";
import { actions as destinationActions } from "../redux/destination";
import { fixDate } from "../utils/date";
import LoadingIndicator from "../components/LoadingIndicator";
import "./DestinationStats.scss";

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, destinationActions), dispatch);

const mapStateToProps = state => ({
  topFive: state.destination.topFive,
  lastFive: state.destination.lastFive
});

// TODO Make search box oblivious of current search source
// OR make it possible to select search source
// OR make it possible to have multiple search sources
class DestinationStats extends React.Component {
  componentDidMount() {
    const { articleId, loadLastFive, loadTopFive } = this.props;
    if (articleId) {
      loadLastFive(articleId);
      loadTopFive(articleId);
    }
  }

  render() {
    const { t, lastFive, topFive } = this.props;
    const dateOpts = { month: "2-digit", day: "2-digit" };

    return (
      <div className="destination-stats--container">
        <h2>
          {t("visitorStatistics")} {new Date().getFullYear()}
        </h2>
        {lastFive.loading && topFive.loading && (
          <div className="destination-stats--loading-box">
            <LoadingIndicator />
          </div>
        )}
        {!lastFive.loading && !topFive.loading && lastFive.stats && lastFive.stats.length > 0 && (
          <div className="destination-stats--flex-box">
            <div className="destination-stats--last-vistiors">
              <h3>{t("lastVisitors")}</h3>
              <ul>
                {lastFive.stats &&
                  lastFive.stats.length &&
                  lastFive.stats.map((t, idx) => (
                    <li key={"lastFive" + t.nick_name + idx}>
                      {t.nick_name} ({fixDate(t.date).toLocaleString(t.locale, dateOpts)})
                    </li>
                  ))}
              </ul>
            </div>
            <div className="destination-stats--top-visitors">
              <h3>{t("topVisitors")}</h3>
              <ul>
                {topFive.stats &&
                  topFive.stats.length &&
                  topFive.stats.map((t, idx) => (
                    <li key={"topfive" + t.nick_name + idx}>
                      {t.nick_name} ({t.visits})
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
        {!lastFive.loading && !topFive.loading && lastFive.stats && lastFive.stats.length === 0 && (
          <span className="destination-stats--no-visitors">{t("noVisitors")}</span>
        )}
      </div>
    );
  }
}

DestinationStats.propTypes = {
  t: PropTypes.func.isRequired,
  articleId: PropTypes.number.isRequired,
  topFive: PropTypes.object.isRequired,
  lastFive: PropTypes.object.isRequired,
  loadLastFive: PropTypes.func.isRequired,
  loadTopFive: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate("DestinationStats")(DestinationStats));
