import React from "react";
import PropTypes from "prop-types";
import { getUserDetails } from "../../redux/user";
import { readMembers } from "../../redux/familymembers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ResultsPage from "../../components/Results/ResultsPage";
import Title from "../../components/Title";
import { getSessionToken } from "@avinet/react-adaptive-auth";

const mapDispatchToProps = dispatch => {
  return {
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    readMembers: bindActionCreators(readMembers, dispatch)
  };
};

const mapStateToProps = state => ({
  trips: state.trips.trips,
  tripDeleted: state.trips.tripDeleted,
  tripsError: state.trips.error,
  user: state.user.user,
  userLoading: state.user.loading,
  members: state.familyMembers.members,
  membersLoading: state.familyMembers.loading,
  friluftsraadStatistics: state.statistics.friluftsraad,
  companyStatistics: state.statistics.company
});

class ResultsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMember: undefined,
      isGuest: false
    };
    this.updateProps = this.updateProps.bind(this);
  }

  componentDidMount() {
    if (!getSessionToken()) {
      this.setState({ isGuest: true });
    }
    this.updateProps();
  }

  componentDidUpdate() {
    this.updateProps();
  }

  updateProps() {
    const { user, userLoading, getUserDetails, members, membersLoading, readMembers } = this.props;
    const { selectedMember } = this.state;
    if (!user && !userLoading) {
      getUserDetails();
    }
    if (user && !members && !membersLoading) {
      readMembers(user.uuid);
    }
    if (members && members.length && selectedMember === undefined) {
      const mainMember = members.find(m => m.is_main_familymember);
      if (mainMember) {
        this.setState({
          selectedMember: mainMember
        });
      }
    }
  }

  render() {
    const { page, user, ...rest } = this.props;
    const { selectedMember, isGuest } = this.state;

    return (
      <div className="tell-tur-page">
        <Title title={page.title} />
        <h1>{page.title}</h1>
        {((user && selectedMember) || isGuest) && (
          <ResultsPage {...rest} page={page} selectedMember={selectedMember} isGuest={isGuest} />
        )}
      </div>
    );
  }
}

ResultsContainer.propTypes = {
  page: PropTypes.object,
  getUserDetails: PropTypes.func,
  user: PropTypes.object,
  userLoading: PropTypes.bool,
  members: PropTypes.array,
  membersLoading: PropTypes.bool,
  readMembers: PropTypes.func,
  pointStatistics: PropTypes.object,
  uniqueStatistics: PropTypes.object,
  readStatistics: PropTypes.func,
  readCompanyStatistics: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsContainer);
