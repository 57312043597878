import React from "react";
import { translate } from "react-translate";
import PropTypes from "prop-types";
import TranslatedServerError from "./TranslatedServerError";
import Select from "react-select";
import config from "../config/TellTur";

class UserRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { proposedFriluftsraad: undefined };
  }

  onTextChange = evt => {
    const { handleChange } = this.props;

    handleChange(evt.target.name, evt.target.value);
  };

  get getGroupedMunicipalities() {
    const { municipalities } = this.props;
    const fylker = config.fylkesnummer;

    const municipalitiesGroupedByCounties = fylker
      .map(fylke => {
        return {
          label: fylke.navn,
          options: municipalities
            .filter(municipality => {
              if (municipality.komm < 999) {
                return fylke.nummer === "03"; // this is bcs kommunenummer are stored in psql as int and for Oslo is stored as 301 instead 0301 becouse of leading 0
              }
              return String(municipality.komm).slice(0, 2) === fylke.nummer;
            })
            .sort((a, b) => (a.navn > b.navn ? 1 : b.navn > a.navn ? -1 : 0))
        };
      })
      .sort((a, b) => (a.navn > b.navn ? 1 : b.navn > a.navn ? -1 : 0));

    return municipalitiesGroupedByCounties;
  }

  componentDidMount() {
    this.getAssociatedFriluftsraad();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.municipality !== this.props.municipality) {
      this.getAssociatedFriluftsraad(this.props.municipality);
    }
  }

  getAssociatedFriluftsraad(newValue) {
    const { allFriluftsraad, friluftsraadMedlemskommuner } = this.props;
    let associatedFriluftsraadArr = [];

    if (!newValue) {
      this.setState({ proposedFriluftsraad: allFriluftsraad });
      return;
    }

    const associatedFriluftsraad = friluftsraadMedlemskommuner.filter(
      fr => fr.kommune === newValue
    );

    if (!associatedFriluftsraad || !associatedFriluftsraad.length) {
      this.setState({ proposedFriluftsraad: allFriluftsraad });
      return;
    }

    associatedFriluftsraadArr = allFriluftsraad.filter(fr =>
      associatedFriluftsraad.some(asf => asf.fr_id === fr.rolle_id)
    );
    this.setState({
      proposedFriluftsraad: [
        {
          label: "Friluftsråd for din bostedskommune",
          options: associatedFriluftsraadArr
        },
        {
          label: "Andre TellTur-friluftsråd",
          options: allFriluftsraad.filter(fr =>
            associatedFriluftsraad.some(asf => asf.fr_id !== fr.rolle_id)
          )
        }
      ]
    });
  }

  render() {
    const {
      username,
      password,
      passwordRepeat,
      mobile,
      municipality,
      municipalities,
      firstname,
      surname,
      yearOfBirth,
      email,
      selectedFriluftsraadId,
      allFriluftsraad,
      validationErrors,
      registerUserError,
      t,
      handleChange,
      handleSubmit
    } = this.props;

    return (
      <form className="tell-tur-form">
        <span className="tell-tur-form--control">
          <label>{t("Username")}</label>
          <input
            name="username"
            type="text"
            value={username}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.username}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Password")}</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.password}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("PasswordRepeat")}</label>
          <input
            name="passwordRepeat"
            type="password"
            value={passwordRepeat}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.passwordRepeat}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Firstname")}</label>
          <input
            name="firstname"
            type="text"
            value={firstname}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.firstname}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Surname")}</label>
          <input
            name="surname"
            type="text"
            value={surname}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.surname}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Mobile")}</label>
          <input
            name="mobile"
            type="tel"
            value={mobile}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.mobile}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Email")}</label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={this.onTextChange}
          />
          <span className="error">{validationErrors.email}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("YearOfBirth")}</label>
          <input
            name="yearOfBirth"
            type="number"
            value={yearOfBirth}
            onChange={this.onTextChange}
            min={new Date().getFullYear() - 120}
            max={new Date().getFullYear()}
          />
          <span className="error">{validationErrors.yearOfBirth}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("Municipality")}</label>
          <Select
            classNamePrefix="editable-search-dropdown"
            placeholder={t("ChooseMunicipality")}
            options={this.getGroupedMunicipalities}
            getOptionLabel={o => o.navn}
            getOptionValue={o => o.komm}
            value={municipalities.find(o => o.komm === municipality)}
            onChange={(di, action) => {
              if (action.action === "select-option") {
                handleChange("municipality", di.komm);
              }
            }}
          />
          <span className="error">{validationErrors.municipality}</span>
        </span>
        <span className="tell-tur-form--control">
          <label>{t("SelectedFriluftsraad")}</label>
          <Select
            classNamePrefix="editable-search-dropdown"
            options={this.state.proposedFriluftsraad}
            placeholder={t("ChooseFriluftsraad")}
            getOptionLabel={o => o.navn}
            getOptionValue={o => o.uuid}
            value={allFriluftsraad.find(o => o.uuid === selectedFriluftsraadId)}
            onChange={(di, action) => {
              if (action.action === "select-option") {
                handleChange("selectedFriluftsraadId", di.uuid);
              }
            }}
          />
          <span className="error">
            {validationErrors.selectedFriluftsraadId}
          </span>
        </span>

        {registerUserError && (
          <TranslatedServerError
            error={registerUserError}
            cssClass={"error u-margin-top-small"}
            meta={{ username }}
          />
        )}
        <div className="tell-tur-form--button-group">
          <button onClick={handleSubmit} className="btn btn-primary">
            {t("Register")}
          </button>
        </div>
      </form>
    );
  }
}

UserRegistrationForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordRepeat: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  municipality: PropTypes.number,
  firstname: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  yearOfBirth: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  selectedFriluftsraadId: PropTypes.string,
  allFriluftsraad: PropTypes.array,
  validationErrors: PropTypes.object,
  registerUserError: PropTypes.object,
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  municipalities: PropTypes.array,
  friluftsraadMedlemskommuner: PropTypes.array
};

export default translate("UserRegistrationForm")(UserRegistrationForm);
