import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

class Modal extends React.PureComponent {
  componentWillMount () {
    document.body.classList.add('modal-open');
  }

  componentWillUnmount () {
    document.body.classList.remove('modal-open');
  }

  render () {
    return (
      <div className='modal--overlay'>
        <div className='modal--content'>{this.props.children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.element
};

export default Modal;
