import { useEffect } from "react";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { ensureMap } from "@avinet/react-openlayers";

const XYZLayer = ensureMap(({ map, url, urls, zIndex, minZoom, maxZoom, crossOrigin, opacity }) => {
  useEffect(() => {
    const layer = new TileLayer({
      source: new XYZ({
        url,
        urls,
        crossOrigin: crossOrigin || "Anonymous"
      }),
      zIndex,
      minZoom,
      maxZoom,
      opacity
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map, url, urls, crossOrigin, zIndex, minZoom, maxZoom, opacity]);

  return null;
});
export default XYZLayer;
