import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import createStore from "./redux/createStore";
import { CmsConfig } from "@avinet/react-adaptive-cms";
import { injectReducer } from "./redux/reducers";
import config from "./config/TellTur";
import "./index.scss";

const initialState = window.__INITIAL_STATE__;
const store = createStore(initialState);

CmsConfig.init(config.adaptiveUrl, config.cms_site_id);

export function addReducerToStore(key, reducer) {
  injectReducer(store, { key, reducer });
}
ReactDOM.render(<App store={store} />, document.getElementById("root"));
