import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { addTrip, clearAddedTrip } from '../redux/trips';
import { readMembers } from '../redux/familymembers';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateDatePattern, isValidDate, isWithinYear, isInFuture } from '../utils/date';
import RegisterTripForm from './RegisterTripForm';
import './RegisterTripPage.scss';

import Title from './Title';

const convertDateString = (dateString, separator) => {
  const dateArray = dateString.split(separator);
  const day = dateArray[0];
  const month = dateArray[1];
  const year = dateArray[2];
  return year + '-' + month + '-' + day;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTrip: bindActionCreators(addTrip, dispatch),
    clearAddedTrip: bindActionCreators(clearAddedTrip, dispatch),
    readMembers: bindActionCreators(readMembers, dispatch),
  };
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  registrationError: state.trips.error,
  tripAdded: state.trips.tripAdded,
  tripsLoading: state.trips.loading,
  members: state.familyMembers.members,
  memberError: state.familyMembers.error,
  membersLoading: state.familyMembers.loading,
});

class RegisterTripPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tripcode: '',
      tripdate: '',
      description: '',
      error: '',
      selectedMemberId: -1,
      validationErrors: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentWillMount() {
    if (this.props.user) {
      this.props.readMembers(this.props.user.uuid);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user) {
      this.props.readMembers(nextProps.user.uuid);
    }
    if (nextProps.tripAdded) {
      this.props.clearAddedTrip();
      this.props.history.push('/mineturer');
    }
    if (nextProps.members && nextProps.members.length && this.state.selectedMemberId === -1) {
      const mainMember = nextProps.members.find((m) => m.is_main_familymember);
      if (mainMember) {
        this.setState({
          selectedMemberId: mainMember.id,
        });
      }
    }
  }

  handleChange(evt) {
    const name = evt.target.name;
    var value = evt.target.value;
    if (name === 'tripdate' && /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value)) {
      value = convertDateString(value, '.');
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateForm(false);
      }
    );
  }

  handleCancel(evt) {
    evt.preventDefault();
    this.props.history.goBack();
  }

  validateForm(submitting) {
    const { user, t } = this.props;
    const { tripcode, tripdate, selectedMemberId } = this.state;
    const validDatePattern = validateDatePattern(tripdate);
    const validationErrors = {};
    if (!user || !user.uuid) {
      validationErrors.general = t('NotLoggedIn');
    }

    if (selectedMemberId < 0) {
      validationErrors.selectedMemberId = t('SelectAMember');
    }

    if (!tripcode || tripcode.length < 2) {
      validationErrors.tripcode = t('TripCodeNotValid');
    }

    if (tripdate.length > 0 && (!validDatePattern || !isValidDate(tripdate))) {
      validationErrors.tripdate = t('TripDateNotValid');
    }

    if (tripdate.length > 0 && validDatePattern && !isWithinYear(tripdate)) {
      validationErrors.tripdate = t('CantRegisterTripsFromLastYear');
    }
    if (tripdate.length === 0 && submitting) {
      validationErrors.tripdate = t('TripDateNotValid');
    }

    if (validDatePattern && isInFuture(tripdate)) {
      validationErrors.tripdate = t('CantRegisterTripsInFuture');
    }

    this.setState({ validationErrors });
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  }

  handleSubmit(evt) {
    evt.preventDefault();

    if (!this.validateForm(true)) {
      return;
    }

    const { tripcode, tripdate, description, selectedMemberId } = this.state;

    const trip = {
      tripcode,
      tripdate,
      userid: selectedMemberId,
      description: description,
    };

    this.props.addTrip(trip);
  }

  render() {
    const { registrationError, members, membersLoading, tripsLoading } = this.props;
    const { tripcode, tripdate, validationErrors, selectedMemberId } = this.state;
    const selectedMemberIdInt =
      typeof selectedMemberId === 'string' ? parseInt(selectedMemberId) : selectedMemberId;
    return (
      <div className="tell-tur-page registrertur">
        <Title title={this.props.t('Title')} />
        <RegisterTripForm
          tripcode={tripcode}
          tripdate={tripdate}
          validationErrors={validationErrors}
          members={members}
          registrationError={registrationError}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          selectedMemberId={selectedMemberIdInt}
          isLoading={membersLoading || tripsLoading}
        />
      </div>
    );
  }
}

RegisterTripPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  addTrip: PropTypes.func,
  registrationError: PropTypes.object,
  tripAdded: PropTypes.string,
  clearAddedTrip: PropTypes.func,
  readMembers: PropTypes.func,
  members: PropTypes.array,
  membersLoading: PropTypes.bool.isRequired,
  tripsLoading: PropTypes.bool.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translate('RegisterTripPage')(RegisterTripPage))
);
