import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { pageActions } from "@avinet/react-adaptive-cms";
import UserRegistrationPage from "../components/UserRegistrationPage";
import LoginPage from "../components/LoginPage";
import MyProfile from "../containers/MyProfile";
import ResetPasswordPage from "../components/ResetPasswordPage";
import ChangePasswordPage from "../components/ChangePasswordPage";
import AddMemberContainer from "../components/AddMemberContainer";
import MyTripsContainer from "../containers/MyTripsContainer";
import ResultsContainer from "../containers/Results/ResultsContainer";
import { withRouter } from "react-router-dom";
import { getPath, fixLinks } from "../utils/Path";
import LoadingIndicator from "../components/LoadingIndicator";
import FriluftsradPage from "../components/FriluftsradPage";
import ProfileValidator from "../components/ProfileValidator";
import config from "../config/TellTur";

const mapStateToProps = state => ({
  page: state.page.page,
  pageLoading: state.page.pageLoading,
  municipalities: state.municipalities.records,
  municipalitiesLoading: state.municipalities.loading,
  user: state.user.user,
  userLoading: state.user.loading
});

const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, pageActions), dispatch);

class HybridPageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: ""
    };
  }

  componentDidMount() {
    const { loadPage, location } = this.props;
    const path = getPath(location);
    this.setState({ currentPath: path });
    loadPage(path);
  }

  componentWillReceiveProps(nextProps) {
    const path = getPath(nextProps.location);
    if (path !== this.state.currentPath) {
      this.setState({ currentPath: path });
      nextProps.loadPage(path);
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    fixLinks(history);
  }

  render() {
    const baseUrl = config.adaptiveUrl;
    const { currentPath } = this.state;
    const { page, pageLoading, municipalities, municipalitiesLoading, user, userLoading } =
      this.props;

    return (
      <div className="tell-tur">
        {(pageLoading || municipalitiesLoading || userLoading) && (
          <div className="loader">
            <LoadingIndicator />
          </div>
        )}
        <React.Fragment>
          {user &&
            (!!currentPath.match(/^(resultater|mineturer)$/) ||
              currentPath.indexOf("friluftsrad/") !== -1) && <ProfileValidator user={user} />}
          {currentPath === "brukerregistrering" && (
            <UserRegistrationPage
              baseUrl={baseUrl}
              page={page}
              municipalities={municipalities}
              loading={municipalitiesLoading}
            />
          )}
          {currentPath === "logginn" && <LoginPage baseUrl={baseUrl} page={page} />}
          {currentPath === "minprofil" && (
            <MyProfile
              baseUrl={baseUrl}
              page={page}
              municipalities={municipalities}
              loading={municipalitiesLoading}
            />
          )}
          {currentPath === "glemtpassord" && <ResetPasswordPage baseUrl={baseUrl} page={page} />}
          {currentPath === "endrepassord" && <ChangePasswordPage baseUrl={baseUrl} page={page} />}
          {currentPath === "nyttmedlem" && <AddMemberContainer baseUrl={baseUrl} page={page} />}
          {currentPath === "mineturer" && <MyTripsContainer baseUrl={baseUrl} page={page} />}
          {currentPath === "resultater" && <ResultsContainer baseUrl={baseUrl} page={page} />}
          {currentPath.indexOf("friluftsrad/") !== -1 && (
            // eslint-disable-next-line no-restricted-globals
            <FriluftsradPage page={page} location={location} />
          )}
        </React.Fragment>
      </div>
    );
  }
}

HybridPageView.propTypes = {
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  municipalities: PropTypes.array.isRequired,
  municipalitiesLoading: PropTypes.bool,
  user: PropTypes.object,
  userLoading: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HybridPageView));
