import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';
import './TextEditableControl.scss';

class TextEditableControl extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editMode: false,
      editedControlValue: props.controlValue
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPencilClick = this.onPencilClick.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (
      (this.state.editedControlValue === null || this.state.editedControlValue === undefined) &&
      nextProps.controlValue
    ) {
      this.setState({ editedControlValue: nextProps.controlValue });
    }
  }

  onSubmit (evt) {
    evt.preventDefault();
    this.setState({ editMode: false });
    this.props.onSubmit(this.state.editedControlValue);
  }

  onBlur (evt) {
    if (
      evt.nativeEvent &&
      evt.nativeEvent.explicitOriginalTarget &&
      evt.nativeEvent.explicitOriginalTarget.nodeName === 'INPUT'
    ) {
      return;
    }
    this.setState({ editMode: false });
  }

  onFocus () {
    this.setState({ editedControlValue: this.props.controlValue });
  }

  onPencilClick () {
    this.setState({ editMode: true }, () => {});
  }

  render () {
    const { editMode, editedControlValue } = this.state;
    const {
      controlClassName,
      labelText,
      controlValue,
      submitText,
      inputType,
      disabled,
      errorText,
      ...props
    } = this.props;
    return (
      <span className={controlClassName}>
        <label>{labelText}</label>
        {!editMode && (
          <div>
            <p className='text-editable-control--value'>{controlValue}</p>
            <span className='text-editable-control--edit-toggle' onClick={this.onPencilClick}>
              <Icon name='pencil' />
            </span>
          </div>
        )}
        {editMode && (
          <div className='text-editable-control--edit-wrapper'>
            <input
              ref={input => input && input.focus()}
              autoFocus
              type={inputType}
              value={editedControlValue}
              onChange={e => this.setState({ editedControlValue: e.target.value })}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              {...props}
            />
            <button
              disabled={disabled}
              onMouseDown={this.onSubmit}
              onSubmit={evt => evt.preventDefault()}
            >
              {submitText}
            </button>
          </div>
        )}
        <span className='error'>{errorText}</span>
      </span>
    );
  }
}

TextEditableControl.defaultProps = {
  controlClassName: '',
  inputType: 'text'
};

TextEditableControl.propTypes = {
  controlClassName: PropTypes.string,
  inputType: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  controlValue: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string
};

export default TextEditableControl;
