import React from "react";
import PropTypes from "prop-types";
import Icon from "../components/Icon";
import Select from "react-select";
import { translate } from "react-translate";
import "./EditableSearchDropdown.scss";

class EditableSearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      valuePropName: props.valuePropName || "value",
      labelPropName: props.labelPropName || "label",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onPencilClick = this.onPencilClick.bind(this);
  }

  onSubmit(evt) {
    const { controlKey, controlValue, onSubmit } = this.props;
    const { valuePropName, selectedItem } = this.state;
    evt.preventDefault();
    const value =
      selectedItem && selectedItem[valuePropName]
        ? selectedItem[valuePropName]
        : controlValue;
    onSubmit(controlKey, value);
    this.setState({ editMode: false, selectedItem: undefined });
  }

  onPencilClick() {
    this.setState({ editMode: true });
  }

  handleCancel(evt) {
    this.setState({
      editMode: false,
    });
  }

  render() {
    const { valuePropName, labelPropName, editMode, selectedItem } = this.state;
    const {
      controlClassName,
      labelText,
      errorText,
      errorClassName,
      dropdownItems,
      collapsible,
      placeholder,
      t,
      styles,
    } = this.props;
    let { controlValue } = this.props;

    const valueFunction = (o) => {
      return o[valuePropName];
    };

    const labelFunction = (o) => {
      return o[labelPropName];
    };

    let flatten = [];

    !dropdownItems.find((item) => item.options)
      ? (flatten = dropdownItems)
      : dropdownItems.forEach((item) =>
          item.options.map((option) => flatten.push(option))
        );

    const valueObject = flatten.find((m) => m[valuePropName] === controlValue);

    if (valueObject) {
      controlValue = valueObject[labelPropName];
    }

    return (
      <React.Fragment>
        <div
          className={[
            controlClassName,
            editMode ? "editable-search-dropdown--open" : "",
          ].join(" ")}
        >
          {labelText && <label>{labelText}</label>}
          {!editMode && (
            <React.Fragment>
              <span
                className="editable-search-dropdown--edit-toggle"
                onClick={this.onPencilClick}
              >
                <Icon name="pencil" />
              </span>
              <div className="editable-search-dropdown--label">
                <p className="editable-search-dropdown--value">
                  {controlValue}
                </p>
              </div>
            </React.Fragment>
          )}

          {editMode && (
            <div
              className={[
                "editable-search-dropdown--edit-wrapper",
                !editMode ? "closed" : "open",
                collapsible ? "collapsible" : "",
              ].join(" ")}
            >
              <div className="dropdown-container">
                <Select
                  classNamePrefix="editable-search-dropdown"
                  placeholder={placeholder}
                  options={dropdownItems}
                  getOptionLabel={labelPropName && labelFunction}
                  getOptionValue={valuePropName && valueFunction}
                  value={selectedItem || valueObject}
                  autoFocus
                  onBlur={(evt) => this.handleCancel(evt)}
                  onChange={(di, action) => {
                    if (action.action === "select-option") {
                      this.setState({ selectedItem: di });
                    }
                  }}
                  styles={styles}
                />
              </div>
              <button onMouseDown={this.onSubmit}>{t("Save")}</button>
            </div>
          )}
          <span className={errorClassName || "error"}>{errorText}</span>
        </div>
      </React.Fragment>
    );
  }
}

EditableSearchDropdown.defaultProps = {
  controlClassName: "",
};

EditableSearchDropdown.propTypes = {
  controlClassName: PropTypes.string,
  labelText: PropTypes.string,
  controlValue: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  controlKey: PropTypes.string,
  errorText: PropTypes.string,
  errorClassName: PropTypes.string,
  dropdownItems: PropTypes.array,
  collapsible: PropTypes.bool,
  labelPropName: PropTypes.string,
  valuePropName: PropTypes.string,
  placeholder: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default translate("EditableDropdown")(EditableSearchDropdown);
