import React from "react";
import PropTypes from "prop-types";
import { Map, WmsLayer, Zoom } from "@avinet/react-openlayers";
import { translate } from "react-translate";
import ParkingLayer from "./ParkingLayer";
import VectorTileLayer from "./VectorTileLayer";
import XYZLayer from "./XYZLayer";

class DefaultMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: window.innerWidth > 1260 ? [3000000, 9900000] : [1250502, 8096129],
      zoom: window.innerWidth > 1260 ? 5 : 3
    };
  }

  render() {
    const { children, t } = this.props;
    const { center, zoom } = this.state;

    return (
      <Map
        center={center}
        minZoom={window.innerWidth > 1260 ? 5 : 3}
        maxZoom={20}
        zoomInTipText={t("zoomInTip")}
        zoomOutTipText={t("zoomOutTip")}
        zoom={zoom}
        track
        hitTolerance={20}
        hideScaleLine={true}
      >
        <Zoom zoomInTipText={t("zoomInTip")} zoomOutTipText={t("zoomOutTip")} />
        <VectorTileLayer url="https://vt.avinet.no/3857/public.land/{z}/{x}/{y}.pbf" />
        <WmsLayer
          id="europakart"
          layerName={[
            "Vmap0Hav",
            "Vmap0Land",
            "Land",
            "Vmap0Kystkontur",
            "Vmap0AdministrativeGrenser"
          ].join(",")}
          version="1.1.1"
          uri="https://openwms.statkart.no/skwms1/wms.europa?"
          singleTile
          zIndex={-4}
        />
        <XYZLayer
          url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png"
          zIndex={-3}
        />
        <ParkingLayer />
        {children}
      </Map>
    );
  }
}

DefaultMap.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate("DefaultMap")(DefaultMap);
