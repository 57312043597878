import request from "superagent";
import { getSessionToken, authReducerActions } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

// ------------------------------------
// Constants
// ------------------------------------
export const READING_MEMBERS = "READING_MEMBERS";
export const MEMBERS_READ = "MEMBERS_READ";

export const ADDING_MEMBER = "ADDING_MEMBER";
export const MEMBER_ADDED = "MEMBER_ADDED";

export const DELETING_MEMBER = "DELETING_MEMBER";
export const MEMBER_DELETED = "MEMBER_DELETED";

export const CLEAR_MEMBERS = "CLEAR_MEMBERS";
// ------------------------------------
// Actions
// ------------------------------------
const addingMember = () => ({
  type: ADDING_MEMBER
});

const clearMembersAction = () => ({
  type: CLEAR_MEMBERS
});

const memberAdded = errorMessage => ({
  type: MEMBER_ADDED,
  payload: {
    error: errorMessage
  }
});

const deletingMember = () => ({ type: DELETING_MEMBER });

const memberDeleted = errorMessage => ({
  type: MEMBER_DELETED,
  payload: {
    error: errorMessage
  }
});

const readingMembers = () => ({ type: READING_MEMBERS });

const membersRead = (errorMessage, members) => ({
  type: MEMBERS_READ,
  payload: {
    error: errorMessage,
    members
  }
});

// ------------------------------------
// Action creators
// ------------------------------------
export function clearMembers() {
  return dispatch => {
    dispatch(clearMembersAction());
  };
}

export function addMember(member, callback) {
  return dispatch => {
    dispatch(addingMember());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/FamilyMembers.asmx/Create")
      // .post('http://localhost/a_a3/WebServices/telltur/FamilyMembers.asmx/Create')
      .send({
        data: member
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", "nb")
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(memberAdded({ message: "Error returned from service" }));
          callback();
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            memberAdded(
              response.exception ? response.exception : { msg: "Error returned from service" }
            )
          );
          callback();
          return;
        }
        dispatch(memberAdded(null));
        callback();
      });
  };
}

export function deleteMember(id, callback) {
  return dispatch => {
    dispatch(deletingMember());

    request
      .post(config.adaptiveUrl + "WebServices/telltur/FamilyMembers.asmx/Delete")
      // .post('http://localhost/a_a3/WebServices/telltur/FamilyMembers.asmx/Delete')
      .send({
        id
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", "nb")
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(memberDeleted({ message: "Error returned from service" }));
          callback();
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(memberDeleted("Error returned from service"));
          callback();
          return;
        }
        dispatch(memberDeleted(null));
        callback();
      });
  };
}

export function readMembers(userUuid) {
  return dispatch => {
    dispatch(readingMembers());
    // userUuid = 'a68338f6-f8b2-473b-b692-3d2a5237c89b'; // TEST!
    request
      .post(config.adaptiveUrl + "WebServices/telltur/FamilyMembers.asmx/Read")
      // .post('http://localhost/a_a3/WebServices/telltur/FamilyMembers.asmx/Read')
      .send({
        user_uuid: userUuid
      })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", "nb")
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(membersRead({ message: "Error returned from service" }));
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(membersRead(response.exception || { message: "Error returned from service" }));
          return;
        }
        var records = response.data.find(d => d.key === "records");
        dispatch(membersRead(null, records.value));
      });
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  error: null,
  members: null
};

export function familyMembersReducer(state = initialState, action) {
  switch (action.type) {
    case ADDING_MEMBER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case CLEAR_MEMBERS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        members: null
      });
    case MEMBER_ADDED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error ? action.payload.error : null
      });
    case READING_MEMBERS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        members: null
      });
    case MEMBERS_READ:
      return Object.assign({}, state, {
        loading: false,
        members: action.payload.members,
        error: action.payload.error ? action.payload.error : null
      });
    case DELETING_MEMBER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case MEMBER_DELETED:
      return Object.assign({}, state, {
        error: action.payload.error ? action.payload.error : null,
        loading: false
      });
    default:
      return state;
  }
}
