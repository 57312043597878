import request from "superagent";
import { getSessionToken } from "@avinet/react-adaptive-auth";
import config from "../config/TellTur";

// ------------------------------------
// Constants
// ------------------------------------
export const TOP_FIVE_LOADING = "TOP_FIVE_LOADING";
export const TOP_FIVE_LOADED = "TOP_FIVE_LOADED";
export const LAST_FIVE_LOADING = "LAST_FIVE_LOADING";
export const LAST_FIVE_LOADED = "LAST_FIVE_LOADED";

// ------------------------------------
// Actions
// ------------------------------------
const loadTopFive = destinationId => {
  return dispatch => {
    dispatch({ type: "TOP_FIVE_LOADING", payload: true });

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Trips.asmx/ReadTopVisitorsDestination")
      // .post('http://localhost/a_a3/WebServices/telltur/Trips.asmx/ReadTopVisitorsDestination')
      .send({
        start: 0,
        limit: 5,
        filter: {
          filterColumns: [
            {
              name: "turmaal_id",
              value: destinationId,
              comparisonOperator: "=",
              logicalOperator: "AND"
            },
            {
              name: "date",
              value: new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
              comparisonOperator: ">=",
              logicalOperator: "AND"
            }
          ]
        }
      })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch({
            type: "TOP_FIVE_LOADED",
            payload: {
              topFive: [],
              topFiveLoading: false
            }
          });
          return;
        }
        response = response.body.d;

        dispatch({
          type: "TOP_FIVE_LOADED",
          payload: {
            topFive: response.records,
            topFiveLoading: false
          }
        });
      });
  };
};

const loadLastFive = destinationId => {
  return dispatch => {
    dispatch({ type: "LAST_FIVE_LOADING", payload: true });

    request
      .post(config.adaptiveUrl + "WebServices/telltur/Trips.asmx/ReadLastVisitorsDestination")
      // .post('http://localhost/a_a3/WebServices/telltur/Trips.asmx/ReadLastVisitorsDestination')
      .send({
        start: 0,
        limit: 5,
        filter: {
          filterColumns: [
            {
              name: "turmaal_id",
              value: destinationId,
              comparisonOperator: "=",
              logicalOperator: "AND"
            },
            {
              name: "date",
              value: new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
              comparisonOperator: ">=",
              logicalOperator: "AND"
            }
          ],
          sortColumns: [
            {
              name: "date",
              sortDesc: true
            }
          ]
        }
      })
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", getSessionToken())
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch({
            type: "LAST_FIVE_LOADED",
            payload: {
              lastFive: [],
              lastFiveLoading: false
            }
          });
          return;
        }
        response = response.body.d;

        dispatch({
          type: "LAST_FIVE_LOADED",
          payload: {
            lastFive: response.records,
            lastFiveLoading: false
          }
        });
      });
  };
};

export const actions = {
  loadLastFive,
  loadTopFive
};

// ------------------------------------
// Action Handler
// ------------------------------------
const ACTION_HANDLERS = {
  [TOP_FIVE_LOADING]: (state, action) => ({
    ...state,
    topFive: { loading: action.payload }
  }),
  [TOP_FIVE_LOADED]: (state, action) => ({
    ...state,
    topFive: {
      loading: action.payload.topFiveLoading,
      stats: action.payload.topFive
    }
  }),
  [LAST_FIVE_LOADING]: (state, action) => ({
    ...state,
    lastFive: {
      loading: action.payload
    }
  }),
  [LAST_FIVE_LOADED]: (state, action) => ({
    ...state,
    lastFive: {
      loading: action.payload.lastFiveLoading,
      stats: action.payload.lastFive
    }
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  topFive: {
    loading: false,
    stats: []
  },
  lastFive: {
    loading: false,
    stats: []
  }
};

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
