import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./TellturArticleListComponent.scss";

export default class TellturArticleListComponent extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onArticleOpen: PropTypes.func.isRequired,
    thumbnailClassName: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    article: PropTypes.object.isRequired,
    validTitle: PropTypes.func.isRequired,
    cleanText: PropTypes.func.isRequired,
    loadImage: PropTypes.bool
  };

  render() {
    const {
      t,
      onArticleOpen,
      thumbnailClassName,
      color,
      thumbnail,
      article,
      loadImage,
      validTitle,
      cleanText
    } = this.props;
    return (
      <div
        className={
          thumbnailClassName +
          (thumbnail ? " articleList--withThumbnail" : "") +
          " articleList--entry clearfix"
        }
        onMouseOver={() => article.olFeature.set("hover", true)}
        onMouseOut={() => article.olFeature.set("hover", false)}
        style={{ borderTop: "6px solid " + color }}
      >
        {loadImage ? (
          <img
            data-original={thumbnail}
            src={thumbnail}
            className="loaded"
            alt=""
          />
        ) : (
          <img data-original={thumbnail} alt="" />
        )}
        <div className="caption">
          {validTitle(article.properties.title) && (
            <h4>{article.properties.title}</h4>
          )}
          <div
            className="articleList--content"
            dangerouslySetInnerHTML={{
              __html: cleanText(article.properties.content)
            }}
          />
          <div
            className={[
              "telltur-article-list-component--gradering",
              article.properties.gradering &&
                article.properties.gradering.toLowerCase()
            ].join(" ")}
          >
            {article.properties.gradering && article.properties.gradering}
          </div>
          <Button
            bsStyle="link"
            className="read-more-btn"
            onClick={() => onArticleOpen(article)}
          >
            {t("ReadMore")}
          </Button>
        </div>
      </div>
    );
  }
}
