import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { getPageSections } from '../utils/Page';
import Icon from './Icon';
import TranslatedServerError from './TranslatedServerError';
import './AddMemberPage.scss';

class AddMemberPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      serverError: null,
      newUserNickname: '',
      newUserYearOfBirth: '',
      membersToDelete: [],
    };

    this.reload = this.reload.bind(this);
    this.deleteMembers = this.deleteMembers.bind(this);
    this.updateMembersToDelete = this.updateMembersToDelete.bind(this);
    this.addNew = this.addNew.bind(this);
  }

  reload() {
    this.setState({
      error: null,
      newUserNickname: '',
      newUserYearOfBirth: '',
      membersToDelete: [],
    });
    this.props.readMembers(this.props.user.uuid);
  }

  deleteMembers() {
    this.setState({ serverError: null });
    this.state.membersToDelete.forEach((id) => this.props.deleteMember(id, () => this.reload()));
  }

  validateName(name) {
    if (name.length < 2) {
      return false;
    }
    return true;
  }

  validateYear(year) {
    if (year < 1900) {
      return false;
    }
    if (year > new Date().getFullYear()) {
      return false;
    }
    return true;
  }

  updateMembersToDelete(id) {
    var m = this.state.membersToDelete.slice();
    var foundAt = m.findIndex((mtd) => mtd === id);
    if (foundAt > -1) {
      m = m.filter((mtd) => mtd !== id);
      this.setState({ membersToDelete: m });
    } else {
      m.push(id);
      this.setState({ membersToDelete: m });
    }
  }

  generateMemberList(members) {
    if (members) {
      members = members.filter((m) => !m.is_main_familymember);
      return members.map((m) => {
        return (
          <div key={m.id} className="member-list--row">
            <div className="cell name-cell">{m.nick_name}</div>
            <div className="cell year-cell">{m.yearofbirth}</div>
            <div onClick={() => this.updateMembersToDelete(m.id)} className="cell delete-cell">
              <div className="checkbox">
                {this.state.membersToDelete.findIndex((memberId) => memberId === m.id) > -1 && (
                  <Icon name="checkmark" />
                )}
              </div>
              <span>{this.props.t('Delete')}</span>
            </div>
          </div>
        );
      });
    }
  }

  addNew(evt, member, callback) {
    const { members, t, addNewMember } = this.props;
    evt.preventDefault();
    if (members === undefined || members === null || members.length === 0) {
      this.setState({ error: { message: t('FriluftsraadNotValid') } });
      return;
    }
    const mainMember = this.props.members.find((m) => m.is_main_familymember === true);
    if (mainMember === undefined) {
      this.setState({ error: { message: t('FriluftsraadNotValid') } });
      return;
    }
    member.selected_friluftsraad_uuid = mainMember.selected_friluftsraad_uuid;
    addNewMember(member, callback);
  }

  render() {
    const { t, page, membersLoading, userLoading, user, members, serverError } = this.props;
    const { membersToDelete, newUserNickname, newUserYearOfBirth, error } = this.state;
    const sections = getPageSections(page);
    const hasMembersToDelete = membersToDelete.length > 0;
    const nameIsValid = this.validateName(newUserNickname);
    const yearIsValid = this.validateYear(newUserYearOfBirth);

    return (
      <div className="tell-tur-page-form-container">
        <h1>{page.title}</h1>
        {sections && (
          <div
            className="tell-tur-page--intro-text"
            dangerouslySetInnerHTML={{ __html: sections['AddNewUserSection'] }}
          />
        )}
        {user && (
          <form
            className="tell-tur-form"
            onSubmit={(e) =>
              this.addNew(e, { newUserNickname, newUserYearOfBirth, gender: '' }, this.reload)
            }
          >
            {error && <span className="tell-tur-form--error">{error.message}</span>}
            {serverError && <TranslatedServerError error={serverError} />}
            <span className="tell-tur-form--control flex-row">
              <label className="flex-members-large">{t('Nickname')}</label>
              <label className="flex-members-small">{t('Year')}</label>
              <input
                className="flex-members-large"
                type="text"
                value={newUserNickname}
                onChange={(e) => this.setState({ newUserNickname: e.target.value })}
              />
              <input
                className="flex-members-small"
                type="number"
                value={newUserYearOfBirth}
                onChange={(e) => this.setState({ newUserYearOfBirth: e.target.value })}
              />
            </span>
            <div className="tell-tur-form--button-group">
              <button
                type="submit"
                disabled={userLoading || membersLoading || !nameIsValid || !yearIsValid}
                className="btn btn-primary"
              >
                {t('AddNewUser')}
              </button>
            </div>
          </form>
        )}
        {sections && (
          <div
            className="tell-tur-page--intro-text"
            dangerouslySetInnerHTML={{ __html: sections['ExistingUserSection'] }}
          />
        )}
        {user && (
          <div className="member-list--container">
            <div className="member-list">{this.generateMemberList(members)}</div>
            <div className="tell-tur-form--button-group">
              <input
                onClick={this.deleteMembers}
                type="button"
                className="btn btn-primary"
                disabled={userLoading || membersLoading || !hasMembersToDelete}
                value={t('Delete')}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

AddMemberPage.propTypes = {
  page: PropTypes.object,
  readMembers: PropTypes.func,
  members: PropTypes.array,
  serverError: PropTypes.object,
  membersLoading: PropTypes.bool,
  userLoading: PropTypes.bool,
  user: PropTypes.object,
  t: PropTypes.func,
  addNewMember: PropTypes.func,
  deleteMember: PropTypes.func,
};

export default translate('AddMemberPage')(AddMemberPage);
